/**
 * This file handles all network related calls for simplicity
 */
import { createFFmpeg, fetchFile, FFmpeg, FSMethodNames } from "@ffmpeg/ffmpeg";
import {
  base64HashCamio404,
  base64KeyCamio404,
} from "../public/placeholder-videos/camio-404";
import {
  base64HashCamioPleaseWait,
  base64KeyCamioPleaseWait,
} from "../public/placeholder-videos/camio-please-wait";
import {
  base64HashCamioTotalFailure,
  base64KeyCamioTotalFailure,
} from "./../public/placeholder-videos/camio-total-failure";
import { fs } from "memfs";
import axios, { CancelTokenSource } from "axios";
import { DownloadProgress, VideoProcesses, Percentages } from "./types";
import JSZip from "jszip";

/** The dynamic global variables used throughout this file */
export type Globals = {
  // the FFMPEG object
  FFMPEG?: FFmpeg;

  // This is passed to the vue app and listened to... like a buffer for passing messages. The app will take the last index
  // as the "most recent" video
  blobs: string[];

  // keep track of the files in the file list
  fileListFiles: string[];

  // Keep track of the intervals that are running
  runningIntervalIds: ReturnType<typeof setInterval>[];

  // The average framerates of the videos
  videoFPS: number;

  // The worker that ffmpeg.js files are run through in order to not block the main thread
  ffmpegWorker?: Worker;

  // Tells us when the worker is ready to accept commands (it's loaded)
  workerReady: boolean;

  // Allows us to cancel in-flight requests.
  cancelTokenSources: CancelTokenSource[];

  // Tracks the downloads (passed in via the fetch function and watched by camioViewer.vue)
  downloadTracker: DownloadProgress;
  downloadCallback?: (progress: DownloadProgress) => void;

  // Keeps track of ffmpeg processing for use (IMPORTANT: These should always be surrounded by a mutex to avoid race conditions)
  videoBeingProcessed: number;
  currentlyRunningCommand: VideoProcesses;

  // Used to implement a mutex lock system
  locked: boolean;

  // Used to force us to use ffmpeg js (either via a window global variable
  // or if the wasm version fails)
  forceFfmpegJs: boolean;

  // Used in case stuff fails, we can still download all the files.
  urlsToFetch: string[];
  videoLengthByVideo: Map<string, number>;
  isBucketMissingVideo: boolean;

  // Used to keep track if everything has failed and we shouldn't continue
  hasFailed: boolean;

  // Ffmpeg gives us useful information in the logs, we want to use that for framerate and stuff
  // these are overwritten frequently, so only should be good for most recent ffmpeg command
  // see https://stackoverflow.com/a/9400527/17929268 for explanation
  mostRecentFPS: number[];
  mostRecentTBR: number[];
  mostRecentTBC: number[];
  mostRecentTBN: number[];

  // This is simply a number that counts up and allows threads to identify if they should be executing commands
  viewerEmbedId: number;

  // If the wasm ffmpeg is loading, we need to know to wait for it.
  loadingFfmpeg: boolean;

  // tells the ui that at least one of the videos is missing
  atLeastOneVideoMissing: boolean;
};

/** The static global variables used as essentially settings. */
export const GLOBAL_STATIC = (() => {
  const noTypeWindow = window as any;
  /** Static variables */
  const DELAY = 500;
  const BACKGROUND_RETRY_INTERVAL = 5 * 1000; // every 5 seconds
  const MAX_RETRIES = 3;
  const DEFAULT_VIDEO_LENGTH: number =
    noTypeWindow["DEFAULT_VIDEO_LENGTH"] || 10;
  // since this has to happen at runtime, we want to check the url for a parameter (so can debug in firefox) too before assuming false

  const SHOW_LOGS: boolean =
    noTypeWindow["SHOW_NEW_VIEWER_LOGS"] ||
    !!new URLSearchParams(window.location.search).get("showviewerlogs") ||
    false;
  const IS_CROSS_ORIGIN_ISOLATED: boolean =
    noTypeWindow.crossOriginIsolated || false;
  const FILE_LIST_NAME = "fileList.txt";
  const IS_RESTRICTED_VIEWER: boolean | undefined = noTypeWindow.GLOBAL_SETTINGS
    ? noTypeWindow.GLOBAL_SETTINGS["is_restricted_viewer"]
    : true;
  const MINIMUM_ALLOWED_FPS = 20;
  // Is a relatively legit way to check for mobile: https://stackoverflow.com/a/32824825/17929268
  const IS_MOBILE =
    "ontouchstart" in document.documentElement &&
    /mobi/i.test(navigator.userAgent);

  return {
    DELAY,
    BACKGROUND_RETRY_INTERVAL,
    MAX_RETRIES,
    DEFAULT_VIDEO_LENGTH,
    SHOW_LOGS,
    IS_CROSS_ORIGIN_ISOLATED,
    FILE_LIST_NAME,
    IS_RESTRICTED_VIEWER,
    MINIMUM_ALLOWED_FPS,
    IS_MOBILE,
  };
})();

// TODO: Probably best to make a template that we can wholesale replace this value with so we don't have to remember to update
// things when we run cleanUpFetcher. Need to make sure we don't persist across viewer opens though and it's okay to initialize every time.
/** The globals used throughtout this file (need to do it this way so it works with testing) */
export const global: Globals = (() => {
  return {
    videoFPS: 30,
    blobs: [],
    fileListFiles: [],
    runningIntervalIds: [],
    workerReady: false,
    cancelTokenSources: [axios.CancelToken.source()],
    downloadTracker: {},
    videoBeingProcessed: 0,
    currentlyRunningCommand: "" as VideoProcesses,
    locked: false,
    forceFfmpegJs:
      (window as any)["FORCE_FFMPEG_JS"] ||
      new URLSearchParams(window.location.search).get("showviewerlogs") ===
        "js" ||
      false,
    urlsToFetch: [],
    videoLengthByVideo: new Map(),
    isBucketMissingVideo: false,
    hasFailed: false,
    mostRecentFPS: [],
    mostRecentTBR: [],
    mostRecentTBC: [],
    mostRecentTBN: [],
    viewerEmbedId: 0,
    loadingFfmpeg: false,
    atLeastOneVideoMissing: false,
  };
})();

// ---------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
// -------------------------- Business Logic Below ---------------------------------
// ---------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------

/** Function for initializing FFMPEG... this is called in Main.ts when the vue app is created. */
export const initFFMPEG = (retryCount = 0): Promise<void> => {
  // TODO: until ffmpeg.wasm works from a memory standpoint, let's use ffmpeg.js if on mobile
  debugLog("Is this device a mobile device?", GLOBAL_STATIC.IS_MOBILE);

  if (
    GLOBAL_STATIC.IS_CROSS_ORIGIN_ISOLATED &&
    !global.forceFfmpegJs &&
    !GLOBAL_STATIC.IS_MOBILE
  ) {
    global.FFMPEG = createFFmpeg({
      log: true,
      logger: ({ message }) => {
        debugLog(message);
        parseProgressFromFfmpegOutput(message);
        parseMovieInfoFromFfmpegOutput(message);
      },
      corePath: process.env.BASE_URL + "wasm-core/ffmpeg-core.js",
      progress: ({ ratio }) => {
        if (!isNaN(ratio) && ratio < 1) {
          progressUpdate(
            ratio,
            global.currentlyRunningCommand,
            global.videoBeingProcessed + ""
          );
        }
      },
    });
    return new Promise<void>((resolve) => {
      if (global.FFMPEG) {
        global.loadingFfmpeg = true;
        global.FFMPEG.load()
          .then(() => {
            global.loadingFfmpeg = false;

            resolve();
          })
          .catch((e) => {
            debugLog("Caught Load Error. Retrying");
            debugLog(e);
            global.loadingFfmpeg = false;
            if (retryCount >= 0) {
              resolve();
              cleanUpFetcher(retryCount - 1);
            } else {
              resolve();
              // fall back to ffmpeg.js
              global.forceFfmpegJs = true;
              cleanUpFetcher(retryCount);
              initFFMPEG(5);
            }
          });
      } else {
        debugLog("Wasn't able to set up viewer. Retrying.");
        if (retryCount >= 0) {
          cleanUpFetcher(retryCount);
        }
      }
    });
  } else {
    global.forceFfmpegJs = true;
    return createWorker(retryCount);
  }
};

/** Helper function for creating an ffmpeg web worker. */
const createWorker = async (retryCount = 0) => {
  // Need to use the relative path so we can find it on camiolog web
  let ffmpegWorkerjsUrl =
    process.env.BASE_URL + "ffmpeg.js/ffmpeg-worker-mp4.js";

  try {
    const ffmpegWorkerjs = await fetchWithDelay(
      process.env.BASE_URL + "ffmpeg.js/ffmpeg-worker-mp4.js",
      new Map(),
      GLOBAL_STATIC.DELAY,
      GLOBAL_STATIC.MAX_RETRIES,
      0,
      true,
      false,
      false
    );
    debugLog("Got a response from fetching worker:", ffmpegWorkerjs);

    ffmpegWorkerjsUrl = URL.createObjectURL(
      new Blob([ffmpegWorkerjs.buffer], {
        type: "text/javascript",
      })
    );

    debugLog("Created Url for ffmpeg worker " + ffmpegWorkerjsUrl);
  } catch (err) {
    debugLog(
      "Something went wrong when fetching the worker js. Trying to use directly."
    );
    debugLog(err);
  }

  // intitialize a web worker
  global.ffmpegWorker = new Worker(ffmpegWorkerjsUrl, {
    type: "module",
  });

  global.ffmpegWorker.onerror = (error) => {
    // If for whatever reason it fails, we should retry completely
    debugLog(`Worker Failed! Here's why:`, error);
    if (retryCount >= 0) {
      // This also automatically retries
      cleanUpFetcher(retryCount);
    } else {
      debugLog("Total Failure. Page reload required.");
      // TODO: Far in the future, we probably want to play the videos back to back
      handleTotalFailure();
    }
  };

  global.ffmpegWorker.onmessage = (e: any) => {
    const msg = e.data;
    switch (msg.type) {
      case "ready":
        debugLog("ready", msg);
        global.workerReady = true;
        break;
      case "run":
        debugLog("Running the command", msg);
        break;
      case "stdout":
        debugLog("Out: ", msg.data);
        break;
      case "stderr":
        debugLog("Error:", msg.data);
        parseProgressFromFfmpegOutput(msg.data);
        parseMovieInfoFromFfmpegOutput(msg.data);
        break;
      case "done":
        debugLog("Exit:", msg.data);
        if (msg.data.MEMFS.length > 0) {
          runFfmpegFS(
            "writeFile",
            msg.data.MEMFS[0].name,
            msg.data.MEMFS[0].data
          );
        } else {
          debugLog(
            "!!! Didn't get an output from the FFMPEG command. Continuing, but this could be an error !!!"
          );
          // TODO: is this sufficient? Should we retry here?
        }
        global.workerReady = true;
        break;
    }
  };
};

/** A function that makes a thread wait their turn, we only need this because wasm doesn't allow multithreading. */
const myTurn = async () => {
  while (global.locked) {
    await new Promise((res) => setTimeout(res, 10));
  }
};

/** Helper function for making sure ffmpeg is loaded.
 * IMPORTANT NOTE ABOUT THIS FUNCTION:
 * Whenever we load ffmpeg, it forgets about any previously stored artifacts
 * so any video we make before loading via this function will be effectively nonexistant.
 */
const ffmpegAssureReady = async () => {
  // While we are loading ffmpeg, this function needs to wait (logically it would)
  while (global.loadingFfmpeg) {
    await wait(100);
  }

  // If we are using
  if (global.FFMPEG && !global.FFMPEG.isLoaded() && !global.forceFfmpegJs) {
    try {
      global.loadingFfmpeg = true;
      await global.FFMPEG.load();
      global.loadingFfmpeg = false;
    } catch (err) {
      global.loadingFfmpeg = false;
      debugLog("Failed to load ffmpeg with the following error: ", err);
      debugLog("Stack trace for failed load.");
      // now, clean up and force ffmpeg.js to be used.
      // In theory, the while loop should just wait until the worker is ready.
      global.forceFfmpegJs = true;
      cleanUpFetcher();
      // the fetcher has some async code that I don't want to refactor right now
      // so for now just give the async code time to execute
      await wait(500);
      await initFFMPEG(5);
    }
  }

  while (
    !(!!global.FFMPEG && global.FFMPEG.isLoaded()) &&
    !(!!global.ffmpegWorker && global.workerReady)
  ) {
    await wait(100);
  }
};

/** Helper functions for locking and unlocking the mutex */
const lockMutex = () => {
  global.locked = true;
};
const unlockMutex = () => {
  global.locked = false;
};

/** Helper function for setting timeouts. */
const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

/** Helper for empty-ing out an array. */
const emptyArray = (
  array: any[],
  functionToCall: ((el: any) => void) | null = null
) => {
  while (array.length !== 0) {
    if (functionToCall) {
      functionToCall(array.pop());
    } else {
      array.pop();
    }
  }
};

/** Helper for choosing the timescale for the video by choosing the
 * maximum between what we have seen and what we were given by camio
 * See https://stackoverflow.com/a/9400527/17929268 for explanation
 * of how tbn works
 */
const chooseTimescale = () => {
  const validFramerates = global.mostRecentFPS
    .concat(global.videoFPS)
    .concat([GLOBAL_STATIC.MINIMUM_ALLOWED_FPS])
    .concat(global.mostRecentTBR)
    .filter((fps) => fps > 1);

  const validTBN = global.mostRecentTBN
    .map((tbn) => tbn / 1000)
    .filter((tbn) => tbn > 1);

  return `${Math.max(...validFramerates.concat(validTBN))}`;
};

/** Helper function for waiting for the worker to finish. */
const workerIsIdle = (waitTimeMS: number) =>
  new Promise((resolve) => {
    // wait for the worker to be ready
    const intervalId = setInterval(() => {
      if (global.workerReady) {
        clearInterval(intervalId);
        resolve(true);
      }
    }, waitTimeMS);

    global.runningIntervalIds.push(intervalId);
  });

/** Only show logs if we want them to show */
const debugLog = (firstArg: any, ...allOtherArgs: any[]) => {
  let incomingMessage = "";
  const CHAR_LIMIT = 500;
  if (!firstArg) {
    return incomingMessage;
  }
  // Handles the case where we pass in an array buffer (which could be really really large, so we just truncate it)
  if (typeof firstArg[Symbol.iterator] === "function") {
    incomingMessage = firstArg.slice(0, CHAR_LIMIT);
  } else {
    incomingMessage = JSON.stringify(firstArg);
  }
  const truncatedOtherArgs = allOtherArgs
    .map((message) => {
      if (typeof message[Symbol.iterator] === "function") {
        return message.slice(0, CHAR_LIMIT);
      } else {
        return JSON.stringify(message);
      }
    })
    .join(" ");

  const logMessage = [incomingMessage, truncatedOtherArgs]
    .join(" ")
    .substring(0, CHAR_LIMIT);

  if (GLOBAL_STATIC.SHOW_LOGS) {
    if (GLOBAL_STATIC.IS_MOBILE) {
      console.log(logMessage);
    } else {
      console.groupCollapsed(logMessage);
      console.trace();
      console.groupEnd();
    }
  }
  const noTypeWindow = window as any;
  if (!noTypeWindow.CAMIO_VIEWER_VUE_DEBUG_LOGS) {
    noTypeWindow.CAMIO_VIEWER_VUE_DEBUG_LOGS = "";
  }
  // Note: this will blow up if we end up in an infinite processing loop (so if it does start blowing up, that is likely the issue.)
  // Also note that this is reset in the cleanupFetcher function so it's only populated per viewer instance
  noTypeWindow.CAMIO_VIEWER_VUE_DEBUG_LOGS = `${noTypeWindow.CAMIO_VIEWER_VUE_DEBUG_LOGS}${logMessage}\n`;
};

/** Keeps track of progress updates for a loading bar. */
const progressUpdate = (
  percentage: number,
  fieldToUpdate: VideoProcesses | keyof Percentages,
  indexOfVideo: string
) => {
  if (fieldToUpdate === "") {
    debugLog("ERROR: Tried to update progress with empty string!");
    return;
  }

  if (!global.downloadTracker[indexOfVideo]) {
    global.downloadTracker[indexOfVideo] = {
      downloadPercent: 0,
      ffmpegConcatPercent: 0,
      ffmpegTimescalePercent: 0,
      videoMissing: 0,
    };
  }

  global.downloadTracker[indexOfVideo][fieldToUpdate] = percentage;

  // Let the camioViewer know about the update
  if (global.downloadCallback) {
    global.downloadCallback(global.downloadTracker);
  } else {
    debugLog("Failed to notify viewer about a progress update!");
  }
};

/** Parses the progress from the ffmpeg output based on total runtime of video */
const parseProgressFromFfmpegOutput = (output: string) => {
  if (!output) {
    return;
  }
  // TODO: this function does nothing... need to implement logic to divide by total time later so we can return a progress percentage by calling the `progressUpdate()` function.
  // check if this output is actually progress output or just info.
  if (output.includes("time=")) {
    const beginningOfTime = output.indexOf("time=");
    const endOfTime = output.indexOf(" ", output.indexOf("time="));
    const timeStamp = output.slice(beginningOfTime + "time=".length, endOfTime);
    const matched = [
      ...timeStamp.matchAll(/(\d\d):(\d\d):(\d\d).(\d\d)/g),
    ].flat();
    const time = {
      h: Number(matched[1]),
      m: Number(matched[2]),
      s: Number(matched[3]),
      ms: Number(matched[4]),
    };
    const seconds = time.h * 3600 + time.m * 60 + time.s + (time.ms * 1) / 60;
    seconds;
  }
};

/** Parses the framerate, tbn, tbc, and tbr from logs and updates the global variables accordingly. */
const parseMovieInfoFromFfmpegOutput = (log: string) => {
  if (!log) {
    return;
  }
  const isCorrectLog = ["fps", "tbr", "tbn", "tbc"].reduce(
    (prev, curr) => log.includes(curr) && prev,
    true
  );

  if (isCorrectLog) {
    const [, fps, tbr, tbn, tbc] =
      /.* ([0-9.]+) fps, ([0-9.]+)k? tbr, ([0-9.]+)k? tbn, ([0-9.]+)k? tbc.*/.exec(
        log
      ) || [];
    debugLog(
      `Parsed the following -> fps: ${fps}, tbr: ${tbr}, tbn: ${tbn}, tbc: ${tbc}`
    );
    global.mostRecentFPS.push(Number(fps));
    global.mostRecentTBR.push(Number(tbr));
    global.mostRecentTBN.push(Number(tbn));
    global.mostRecentTBC.push(Number(tbc));
    debugLog(
      "The fps, tbr, tbn, and tbc's collected so far",
      global.mostRecentFPS,
      global.mostRecentTBR,
      global.mostRecentTBN,
      global.mostRecentTBC
    );
  }
};

/** If the origin is not isolated (aka safari), use a different library than wasm.
 * input - the ffmpeg command (without the ffmpeg in the beginning) seperated into strings at every space
 * fileNames - the name of the input files (mp4, audio, etc)
 * additionalFilesToInclude - if there were any additional files like a filelist (Note: this can probably be combined with the above, order matters though)
 * runCheck - a tuple that is the file name to check for existance, # retries left, and the function to call if all retries fail, and whether or not a failure here is fatal.
 */
const runFfmpeg = async (
  input: string[],
  fileNames: string[] = [],
  additionalFilesToInclude: string[] = [],
  runCheck?: [string, number, () => any, boolean]
) => {
  if (GLOBAL_STATIC.IS_CROSS_ORIGIN_ISOLATED && !global.forceFfmpegJs) {
    if (global.FFMPEG) {
      await global.FFMPEG.run(...input);
    } else {
      debugLog("Ffmpeg WASM is not defined! Can't run ffmpeg command!");
    }
  } else {
    // here, we want to grab all of the input files and their data
    const MEMFS = fileNames.map((name) => {
      const data = runFfmpegFS("readFile", name);
      return {
        data,
        name,
      };
    });

    // add any additional files (like if we passed in a filelist to read)
    additionalFilesToInclude.forEach((name) => {
      const data = runFfmpegFS("readFile", name);
      MEMFS.push({
        data,
        name,
      });
    });

    // wait for the worker to be ready
    await workerIsIdle(100);
    debugLog("ready, running the inputted args", input, MEMFS);
    // need to set this here because sometimes the message handler takes a second to respond
    // and we get race conditions because it continues through the code despite the worker not being
    // written yet.
    global.workerReady = false;
    if (global.ffmpegWorker) {
      global.ffmpegWorker.postMessage({ type: "run", arguments: input, MEMFS });
    } else {
      debugLog("Ffmpeg worker is not defined! Can't run ffmpeg command!");
      global.workerReady = true;
    }
    await workerIsIdle(100);
  }

  // If there is an output file to check, see if the command succeeded
  // If it did, return, otherwise
  if (runCheck) {
    const [
      outputFileToCheckForExistance,
      retryCount,
      functionToCall,
      isFailureFatal,
    ] = runCheck;
    try {
      // This throws an error if the file doesn't exist!
      runFfmpegFS("readFile", outputFileToCheckForExistance);
    } catch (err) {
      debugLog(
        "!!!Ffmpeg command must have failed because there is no output!!!"
      );

      // If we are out of retries
      if (retryCount - 1 === 0) {
        // if we are in wasm mode, retry the whole thing again in ffmpeg.js
        if (!global.ffmpegWorker && isFailureFatal) {
          // fall back to ffmpeg.js
          global.forceFfmpegJs = true;
          cleanUpFetcher();
          initFFMPEG(5);
        } else {
          functionToCall();
        }
      } else {
        // just try again with one less retry
        const runCheckCopy: typeof runCheck = [...runCheck];
        runCheckCopy[1] = retryCount - 1;
        await runFfmpeg(
          input,
          fileNames,
          additionalFilesToInclude,
          runCheckCopy
        );
      }
    }
  }
};

/** Handles file system read/writes */
const runFfmpegFS = (
  method: FSMethodNames,
  filename: string,
  binaryData: Uint8Array | null = null
) => {
  try {
    // Use WebAssembly if we can
    if (GLOBAL_STATIC.IS_CROSS_ORIGIN_ISOLATED && !global.forceFfmpegJs) {
      if (method === "writeFile") {
        if (global.FFMPEG) {
          global.FFMPEG.FS(method, filename, binaryData as Uint8Array);
        } else {
          debugLog("Can't write! FFMPEG is not defined!");
        }
      } else if (method === "readFile") {
        if (global.FFMPEG) {
          return global.FFMPEG.FS(method, filename);
        } else {
          debugLog("Can't read! FFMPEG is not defined!");
        }
      }
    } else {
      if (method === "writeFile") {
        debugLog("written: ", filename);
        // if write, then take the inputted binary data and save it
        fs.writeFileSync(filename, binaryData as Uint8Array);
      } else if (method === "readFile") {
        debugLog("read: ", filename);
        // if read, then read from the filename and return the result as a u8intarray
        return fs.readFileSync(filename) as Uint8Array;
      }
    }
  } catch (err) {
    debugLog(
      `Failed to ${method} file: ${filename}. Continuing, but this may be a fatal error!`
    );
    debugLog(err);
  }
  // return an empty array on writes to make type checking happy
  return new Uint8Array();
};

/** Handles the processing of videos that have been resolved */
const processVideos = async (
  index: number,
  file: Uint8Array,
  isFirstFile: boolean
): Promise<Uint8Array | void> => {
  // Step 1: Save the recently received file
  const name = `video-#-${index}.mp4`;
  const presaveName = `video-#-${index}-temp.mp4`;
  runFfmpegFS("writeFile", presaveName, file);

  // Keep track of which video we are on for progress
  global.videoBeingProcessed = index;

  // Step 2: Encode all the recieved videos with the same timescale
  // this fixes an issue where videos after error videos are at half speed
  // Thanks to: https://stackoverflow.com/a/42443432
  global.currentlyRunningCommand = "ffmpegTimescalePercent";

  /** If the time scale command fails, just move on and hope that the output won't be in slo mo. */
  const failedTimescaleAdjustment = () => {
    debugLog("Failed time scale adjustment!");
    runFfmpegFS("writeFile", name, file);
  };

  // check the framerate of this video to calculate timescale
  await runFfmpeg(
    ["-i", presaveName, "-c", "copy", "-t", "0", "-f", "null", "-"],
    [presaveName],
    []
  );

  debugLog(
    "Choosing the framerate for the track timescale edit to be (not)" +
      chooseTimescale()
  );

  // adjust timescale
  await runFfmpeg(
    [
      "-i",
      presaveName,
      "-c:v",
      "copy",
      "-max_muxing_queue_size",
      "2048",
      "-video_track_timescale",
      chooseTimescale(),
      "-shortest",
      name,
    ],
    [presaveName],
    [],
    [name, GLOBAL_STATIC.MAX_RETRIES, failedTimescaleAdjustment, false]
  );

  if (global.hasFailed) {
    return;
  }

  // update the final progress to 1 (because of weird behavior with the concat progress reporting)
  progressUpdate(1, global.currentlyRunningCommand, index + "");

  // Step 3.1: we don't need to concat if we've only recieved one video, so just return it
  if (isFirstFile) {
    // nothing to concat so done update the progress bar manually
    progressUpdate(1, "ffmpegConcatPercent", index + "");
    return runFfmpegFS("readFile", name);
  }

  // Step 3.2: Concat every video on the file list together
  global.currentlyRunningCommand = "ffmpegConcatPercent";
  const outputFileName = "output.mp4";
  await runFfmpeg(
    [
      "-f",
      "concat",
      "-safe",
      "0",
      "-i",
      GLOBAL_STATIC.FILE_LIST_NAME,
      "-c",
      "copy",
      outputFileName,
    ],
    [GLOBAL_STATIC.FILE_LIST_NAME],
    global.fileListFiles,
    [outputFileName, GLOBAL_STATIC.MAX_RETRIES, handleTotalFailure, true]
  );

  if (global.hasFailed) {
    return;
  }

  // update the final progress to 1 (because of weird behavior with the concat progress reporting)
  progressUpdate(1, global.currentlyRunningCommand, index + "");

  // Step 4: Read and return the resulting concatenated file
  return runFfmpegFS("readFile", "output.mp4");
};

/** Called when a video comes in after being requested via interval (and it resolving.)
 * Since we have a new video, we need to splice it in to what we already had, we need to fix the concatenated video) */
const handleLateVideoResolution = async (
  file: Uint8Array,
  indexOfVideo: number
): Promise<void> => {
  // Lock anybody else from running an ffmpeg command
  await myTurn();
  lockMutex();

  // We have recieved a video, so want to show loaded instead of requested.
  progressUpdate(0, "videoMissing", indexOfVideo + "");

  // Reprocess the video (concat, timescale)
  const data = await processVideos(indexOfVideo, file, false);

  if (global.hasFailed) {
    return;
  }

  // Then, send the new url to the player
  global.blobs.push(
    URL.createObjectURL(
      new Blob([(data as Uint8Array).buffer], {
        type: "video/mp4", //"application/x-mpegURL"
      })
    )
  );
  unlockMutex();
};

/** This is the worst possible failure. In this case, we want to just essentially give up, show the user some precanned error message, and download their video to their computer. */
export const handleTotalFailure = async () => {
  // Stop all pending requests
  cleanUpFetcher();

  global.hasFailed = true;

  // Fetch the error video
  const fileData = await fetchFile(
    base64KeyCamioTotalFailure + base64HashCamioTotalFailure
  );

  const progressKeys: (keyof Percentages | VideoProcesses)[] = [
    "downloadPercent",
    "ffmpegTimescalePercent",
    "ffmpegConcatPercent",
    "videoMissing",
  ];

  // TODO: Reinstate when the viewer logic is 100% correct
  // Don't download to the user if they are in restricted viewer mode
  // if (GLOBAL_STATIC.IS_RESTRICTED_VIEWER === false) {
  //   // fetch the videos for the user and
  //   const zip = new JSZip();
  //   const zipFilename = "unplayable-events-from-browser.zip";

  //   const files = fetchVideos(
  //     global.urlsToFetch,
  //     global.videoLengthByVideo,
  //     global.isBucketMissingVideo
  //   );
  //   debugLog("Fetched videos");

  //   let count = 0;
  //   files.forEach(async (filePromise, index) => {
  //     debugLog("Starting file:", filePromise);

  //     // wait for file to resolve from a promise
  //     let file: Uint8Array;
  //     try {
  //       file = await filePromise;
  //     } catch (err) {
  //       debugLog("caught error", err);
  //       return;
  //     }

  //     const name = `video-#-${index}.mp4`;
  //     zip.file(name, file, { binary: true });
  //     // increment count, then check if it is the right length
  //     count++;
  //     debugLog(
  //       `File # ${count} done, was it the last one?`,
  //       count == files.length
  //     );

  //     // Update the progress artificially to one when a file is downloaded so we can
  //     // reuse the progress bar at the bottom of the video
  //     progressKeys.forEach((key) => {
  //       progressUpdate(1, key, index + "");
  //     });

  //     if (count == files.length) {
  //       zip.generateAsync({ type: "blob" }).then((content) => {
  //         const url = URL.createObjectURL(content);
  //         const a = document.createElement("a");
  //         a.href = url;
  //         a.download = zipFilename;
  //         a.click();
  //       });
  //     }
  //   });
  // } else {
  //   debugLog("Failure with no permissions. Just update progress.");
  //   // don't do any downloading, but get rid of the progress bar
  //   global.urlsToFetch.forEach((_, index) => {
  //     progressKeys.forEach((key) => {
  //       progressUpdate(1, key, index + "");
  //     });
  //   });
  // }

  // push it as the video to show
  global.blobs.push(
    URL.createObjectURL(
      new Blob([fileData.buffer], {
        type: "video/mp4",
      })
    )
  );
};

/** Function that makes an error video of a specified length by merging premade mp4 videos together */
const makeSampleVideoFromImage = async (
  missingVideoLengthSeconds: number,
  isMissing: boolean
) => {
  // https://superuser.com/questions/1041816/combine-one-image-one-audio-file-to-make-one-video-using-ffmpeg
  // https://trac.ffmpeg.org/wiki/Slideshow
  // wasm can only run one command at a time
  await myTurn();
  lockMutex();
  const encoder = new TextEncoder();
  let fileString = "";
  const fileArray = [];

  const imageString = isMissing
    ? base64KeyCamio404 + base64HashCamio404
    : base64KeyCamioPleaseWait + base64HashCamioPleaseWait;

  // put the 1s video into the VM for every second of missing video
  for (let i = 0; i < missingVideoLengthSeconds; i++) {
    const name = i + "-error-video.mp4";
    runFfmpegFS("writeFile", name, await fetchFile(imageString));
    fileString += `file ${name}\n`;
    fileArray.push(name);
  }

  // save the files for concatenation
  const textFileAsArray = encoder.encode(fileString);
  runFfmpegFS("writeFile", "sampleFileList.txt", textFileAsArray);
  const intermediateName = "temp-error.mp4";

  // Concat all saved files
  await runFfmpeg(
    [
      "-f",
      "concat",
      "-safe",
      "0",
      "-i",
      "sampleFileList.txt",
      "-c",
      "copy",
      intermediateName,
    ],
    ["sampleFileList.txt"],
    fileArray,
    [intermediateName, GLOBAL_STATIC.MAX_RETRIES, handleTotalFailure, true]
  );

  // Then, we need to add fake audio to this video so when we concat, ffmpeg doesn't mute the subsequent ones
  await runFfmpeg(
    [
      "-f",
      "lavfi",
      "-i",
      "anullsrc=channel_layout=stereo:sample_rate=44100",
      "-i",
      intermediateName,
      "-c:v",
      "copy",
      "-c:a",
      "aac",
      "-shortest",
      "error-video.mp4",
    ],
    [intermediateName],
    [],
    ["error-video.mp4", GLOBAL_STATIC.MAX_RETRIES, handleTotalFailure, true]
  );

  const errorVid = runFfmpegFS("readFile", "error-video.mp4");
  unlockMutex();

  return errorVid;
};

/** Fetch with error handling after a certain amount of delays and retries. If failure, create a dummy video because we couldn't fetch the one we need. */
const fetchWithDelay = (
  url: string,
  videoLengthByVideo: Map<string, number>,
  delay: number,
  retries: number,
  indexOfVideo: number,
  fromInterval: boolean,
  isBucketMissingVideo: boolean,
  sendProgressUpdate = true
): Promise<Uint8Array> =>
  new Promise((resolve, reject) => {
    // Following these "best" design retry practices: https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
    const cancelTokenSource = axios.CancelToken.source();
    global.cancelTokenSources.push(cancelTokenSource);

    axios(url, {
      onDownloadProgress: (progress) => {
        if (sendProgressUpdate) {
          progressUpdate(
            progress.loaded / progress.total,
            "downloadPercent",
            indexOfVideo + ""
          );
        }
      },
      responseType: "arraybuffer",
      cancelToken: cancelTokenSource.token,
    })
      .then(async (response) => {
        // We can have better handling here if there are other, non-404 messages, but for now the errors are handled the same.
        if (response.status == 200) {
          resolve(new Uint8Array(response.data));
        } else if (response.status == 404) {
          throw "retry 404";
        } else {
          debugLog(
            `Something went wrong with the request to ${url}. We got status code: ${response.status} "${response.statusText}". \nWe will try again regardless.`
          );
          throw "retry";
        }
      })
      .catch(async (reason) => {
        // if we cancelled the mid-flight request, don't bother with the below logic
        if (axios.isCancel(reason)) {
          debugLog("Request canceled", reason ? reason.message : "");
          return;
        }

        if (retries > 0 && (reason == "retry" || reason === "retry 404")) {
          return wait(delay)
            .then(() =>
              fetchWithDelay(
                url,
                videoLengthByVideo,
                delay,
                retries - 1,
                indexOfVideo,
                fromInterval,
                isBucketMissingVideo
              )
            )
            .then(resolve)
            .catch(reject);
        }

        // Don't repeat the below logic if we have already set up the interval
        if (fromInterval) {
          return;
        }

        // All else has failed, so time to make an error placeholder video
        // TODO: does this need to be more accurate than a second?
        let vidLength = 0;
        if (videoLengthByVideo.get(url)) {
          vidLength = Math.round(videoLengthByVideo.get(url) as number);
        } else {
          vidLength = GLOBAL_STATIC.DEFAULT_VIDEO_LENGTH;
        }

        // We have not recieved a video, so want to show requested instead of loaded.
        progressUpdate(1, "videoMissing", indexOfVideo + "");

        // Make sure ffmpeg is already loaded since this uses ffmpeg commands.
        await ffmpegAssureReady();
        const sampleVideo = await makeSampleVideoFromImage(
          vidLength,
          isBucketMissingVideo
        );

        // If we are making a sample video, that means that at least one of the videos is missing and we want to have the ability to request the box for an urgent upload (https://github.com/CamioCam/feature-requests/issues/1117)
        global.atLeastOneVideoMissing = true;

        // If the videos are missing, then don't bother retrying
        if (!isBucketMissingVideo) {
          // We don't want to fully give up, so we will occasionally still retry in the background
          const retryInBackground = setInterval(async () => {
            // Try to get the file again
            const filePromise = fetchWithDelay(
              url,
              videoLengthByVideo,
              delay,
              retries,
              indexOfVideo,
              true,
              isBucketMissingVideo,
              sendProgressUpdate
            );

            let file: Uint8Array;
            try {
              file = await filePromise;
            } catch (err) {
              debugLog("Video still not ready:", err);
              return;
            }

            // If we got this far then the file must have successfully resolved!
            await handleLateVideoResolution(file, indexOfVideo);
            clearInterval(retryInBackground);
          }, GLOBAL_STATIC.BACKGROUND_RETRY_INTERVAL);

          global.runningIntervalIds.push(retryInBackground);
        }
        return resolve(sampleVideo);
      });
  });

/** Fetches the videos from the passed in urls and concatenates them into one video.
 * IMPORTANT: Only call this once per viewer instance. This function is built on that assumption!
 */
export const fetchVideoAndConcatenate = async (
  videoURLs: string[],
  videoLengthByVideo: Map<string, number>,
  isBucketMissingVideo: boolean,
  videoFrameRate: number,
  downloadProgress: (progress: DownloadProgress) => void
): Promise<string[]> => {
  try {
    // Save the inputs to this function.
    global.urlsToFetch = [...videoURLs];
    global.videoLengthByVideo = videoLengthByVideo;
    global.isBucketMissingVideo = isBucketMissingVideo;

    // globally save the callback for progress updates
    global.downloadCallback = downloadProgress;

    // set the video framerate global for use.
    global.videoFPS = Math.round(videoFrameRate);
    global.viewerEmbedId++;

    const thisViewerId = global.viewerEmbedId;
    // Make a request to fetch all the videos in parallel
    const files = fetchVideos(
      videoURLs,
      videoLengthByVideo,
      isBucketMissingVideo
    );

    let nextIndex = 0;
    const wasmFiles: string[] = [];

    // This fixes an issue where one of the threads claims the lock, the user closes the window to kill the thread, and then it never gives the lock back because it died. This ensures that before we start, there are no locks in place
    unlockMutex();

    // Wait for ffmpeg to finish loading (it won't exist if the page isn't cross origin isolated)
    await ffmpegAssureReady();

    if (global.hasFailed) {
      handleTotalFailure();
      return global.blobs;
    }

    files.forEach(async (filePromise, index) => {
      // Note that any errors that aren't caught here will fail in the global context
      // because this fucntion is async

      // if it is the first video, play it directly from the url
      if (index == 0) {
        // Shoutout to this guy for the message passing idea: https://stackoverflow.com/a/56833053
        global.blobs.push(videoURLs[0]);
      }

      // wait for our turn
      while (nextIndex != index) {
        // these threads loop infinitely
        await wait(3000);
        // If this isn't our instance of the viewer, we are an old thread and should terminate.
        if (thisViewerId !== global.viewerEmbedId) {
          return;
        }
      }

      // wait for file to resolve from a promise
      let file: Uint8Array;
      try {
        file = await filePromise;
      } catch (err) {
        debugLog("caught error", err);
        return;
      }

      // Lock anybody else from running an ffmpeg command
      await myTurn();
      lockMutex();

      // Step 1: Write the name of the post-processed file (hasn't actually been made yet) to the concatenation list
      const name = `video-#-${index}.mp4`;
      const fileString = `file ${name}\n`;
      wasmFiles.push(fileString);
      global.fileListFiles.push(name);
      const encoder = new TextEncoder();
      const textFileAsArray = encoder.encode(wasmFiles.join(""));
      runFfmpegFS("writeFile", GLOBAL_STATIC.FILE_LIST_NAME, textFileAsArray);

      try {
        // Step 2: Process the videos (save file list, convert timescale, concatenate)
        const data = await processVideos(index, file, index == 0);

        // Step 3: Send the returned file back to the app to show in the video player
        global.blobs.push(
          URL.createObjectURL(
            new Blob([(data as Uint8Array).buffer], {
              type: "video/mp4", //"application/x-mpegURL"
            })
          )
        );
      } catch (err) {
        debugLog("Failed while processing videos.");
        debugLog(err);
      }
      unlockMutex();

      // Step 4: Allow the next process to enter
      nextIndex = index + 1;
    });

    // return the blob object immediately (but will get more content pushed to it as time goes on)
    return global.blobs;
  } catch (err) {
    debugLog("Error within the execution of the concatenation flow.", err);

    // Need to return an empty promise so type checking is happy.
    return new Promise<string[]>((_, rej) => rej([]));
  }
};

/** Exposed function for fetching videos from a url. */
export const fetchVideos = (
  videoURLs: string[],
  videoLengthByVideo: Map<string, number>,
  isBucketMissingVideo: boolean
): Promise<Uint8Array>[] => {
  const promises: Promise<Uint8Array>[] = videoURLs.map((url, index) => {
    return fetchWithDelay(
      url,
      videoLengthByVideo,
      GLOBAL_STATIC.DELAY,
      GLOBAL_STATIC.MAX_RETRIES,
      index,
      false,
      isBucketMissingVideo
    );
  });

  return promises;
};

/** Clean up pending stuff. */
export const cleanUpFetcher = (retryCount = 0): void => {
  try {
    emptyArray(global.blobs);
    emptyArray(global.fileListFiles);
    emptyArray(global.runningIntervalIds, clearInterval);
    emptyArray(global.mostRecentFPS);
    emptyArray(global.mostRecentTBR);
    emptyArray(global.mostRecentTBC);
    emptyArray(global.mostRecentTBN);
    (window as any).CAMIO_VIEWER_VUE_DEBUG_LOGS = "";
    global.downloadTracker = {};
    global.videoBeingProcessed = 0;
    global.currentlyRunningCommand = "";
    global.atLeastOneVideoMissing = false;
    emptyArray(global.cancelTokenSources, (token: CancelTokenSource) =>
      token.cancel("Operation canceled during cleanup.")
    );
    if (global.ffmpegWorker && retryCount > 0) {
      global.ffmpegWorker.terminate();
      global.workerReady = false;
      createWorker(retryCount - 1);
      return;
    }

    if (global.FFMPEG && retryCount > 0) {
      initFFMPEG(retryCount - 1);
    } else if (global.FFMPEG) {
      try {
        // Note: this will sometimes fail if ffmpeg is not loaded. We are okay with that scenario for now.
        // This issue is why we switched to using our own version of ffmpeg.wasm: https://github.com/ffmpegwasm/ffmpeg.wasm/issues/242
        // Note that we also added caching of the core js files so they only have to be fetched once, so we may want to stay on our own version
        global.FFMPEG.exit();
        // global.FFMPEG.load();
      } catch (err) {
        debugLog(
          "Couldn't exit and reload ffmpeg. Hopefully this is okay!",
          err
        );
      }
    }
  } catch (err) {
    debugLog("Failure in clean up fetcher function.", err);
  }
};

// need to export helpers so we can unit test them. DON'T USE THESE ON THEIR OWN
export const helpers = {
  chooseTimescale,
  ffmpegAssureReady,
  createWorker,
  myTurn,
  lockMutex,
  unlockMutex,
  wait,
  emptyArray,
  workerIsIdle,
  debugLog,
  progressUpdate,
  parseProgressFromFfmpegOutput,
  runFfmpeg,
  runFfmpegFS,
  processVideos,
  handleLateVideoResolution,
  makeSampleVideoFromImage,
  fetchWithDelay,
};
