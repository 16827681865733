var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showComplianceLogger)?_c('div',{staticClass:"compliance-logger-container-full",class:{
    'fade-in': _vm.localHoverForTimeout,
    'fade-out': !_vm.localHoverForTimeout,
  }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.complianceLabelFound),expression:"complianceLabelFound"}],ref:"overallCompliance",staticClass:"overall-compliance"},[_c('div',[(!_vm.compliant)?_c('span',[_c('b-icon',{attrs:{"icon":"exclamation-lg","color":"red"}}),_vm._v(" Noncompliant ")],1):_c('span',[_c('b-icon',{attrs:{"icon":"check-lg","color":"green"}}),_vm._v(" Compliant ")],1)]),(_vm.resultsLabelFound && !!_vm.resultsUrl)?_c('b-icon',{attrs:{"icon":"dot"}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultsLabelFound && !!_vm.resultsUrl),expression:"resultsLabelFound && !!resultsUrl"}],staticClass:"link"},[_c('a',{attrs:{"href":_vm.resultsUrl,"target":"_blank","data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"right","title":"View the results in Vector Search"}},[_c('span',[_vm._v("View Results")])])])],1),_c('div',{ref:"complianceLogger",staticClass:"compliance-logger-container"},[_vm._l((_vm.fullInfoComplianceObjects),function(complianceObject,complianceIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!complianceObject.collapsed),expression:"!complianceObject.collapsed"}],key:`compliance-control-${complianceObject.id}`,staticClass:"compliance-control-item",class:complianceObject.customClasses,style:({ cursor: complianceObject.cursor }),on:{"click":function($event){$event.stopPropagation();return complianceObject.expandFunction()}}},[_c('div',{staticClass:"full-info-label-list"},[(complianceObject.displayText)?[_c('div',[_c('span',{class:'badge ' +
                  (complianceObject.compliant == 'COMPLIANT'
                    ? 'bg-success'
                    : 'bg-danger')},[_vm._v(_vm._s(complianceObject.compliant))]),_vm._v(" "+_vm._s(complianceObject.title)+" ")]),_c('div',{staticClass:"compliance-rm compliance-control-row"},[_vm._v(" "+_vm._s(complianceObject.displayText)+" ")])]:[_c('div',{staticClass:"overflow-title"},[_vm._v(" "+_vm._s(complianceObject.overflowEventTitle)+" ")])]],2)])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }