import dayjs from "dayjs";

export enum PlayerTypes {
  NONE = "NONE",
  PANZOOM = "PANZOOM",
}

export enum MapZoomLevels {
  OFF = "OFF",
  CLOSE = "CLOSE",
  FAR = "FAR",
}

export type VideoProcesses = keyof Omit<Percentages, "downloadPercent"> | "";

export type Percentages = {
  downloadPercent: number;
  ffmpegTimescalePercent: number;
  ffmpegConcatPercent: number;
  videoMissing: number; // 1 if true, 0 if false
};

export type DownloadProgress = {
  [videoIndex: string]: Percentages;
};

export type VideoSource = {
  bucket?: Bucket;
  content_type: string;
  date_created: string;
  id: string;
  interest_level: number;
  interest_score: number;
  labels: string[];
  movie: Movie;
  skip: boolean;
  source: string;
  src: string;
  video_length: number;
};

export type Bucket = {
  annotations: any[];
  aspect_ratio: number;
  bucket_id: string;
  color: string;
  count: number;
  cover_image_id: string;
  cover_image_k: number;
  croppings: Croppings;
  croppings_info?: CroppingsInfo[];
  croppings_loaded: boolean;
  croppings_server_id: string;
  deferredCoverRequest: any;
  deferredHDImagesRequest: any;
  deferredThumbnailRequest: any;
  earliest_date: string; // e.g. "2021-05-28T02:02:46.675-0000"
  earliest_date_plus: Timestamp;
  groups: string[];
  hidden: boolean;
  images: ImageInfo[];
  interactions: boolean;
  interest_level: number;
  label_probabilities: { [label: string]: number };
  labels: string[];
  latest_date: string; //e.g. "2021-05-28T02:02:49.915-0000"
  latest_date_plus: Timestamp;
  max_interest_level: number;
  movies: Movie[];
  recordedViews: any;
  route: any[];
  saved: boolean;
  skimDirectionChanges: number;
  source: string; // e.g. "Front Window"
  user_id: string;
  videos: VideoSource[];
};

export type Croppings = {
  access_token: string;
  croppings: AuthCropping[];
};

export type AuthCropping = {
  aliased_raw_ml_praxis_results?: any;
  area?: number;
  capabilities?: string[];
  crop_id: number;
  flow_idx?: number;
  frame_idx: (string | number)[];
  image?: ImageMeta;
  image_idx?: number;
  labels: LabelInfo;
  original_bbox_percent?: number[][];
  raw_ml_praxis_results?: any;
  real_frame_idx?: (string | number)[];
  region_bbox_percent: number[][];
  region_idx?: number;
  shape: number[];
  timestamp: Timestamp;
};

export type CroppingsInfo = {
  crop_id: number;
  frame_idx: (string | number)[];
  image_available: boolean;
  labels: LabelInfo;
  region_bbox_percent: number[][];
  shape: number[];
  timestamp: Timestamp;
};

export type ImageMeta = {
  meta_class: string;
  data: string;
};

export type ImageInfo = {
  access_token: string;
  bucket?: Bucket;
  content_type: string;
  date_created: string; // e.g."2021-06-02T23:25:42.959-0000"
  id: string;
  image_bytes: string;
  interest_level: number;
  interest_score: number;
  labels: string[];
  source: string; // e.g. "Front East"
};

export type LabelInfo = {
  meta_class: string;
  set: string[];
};

export type Movie = {
  content_type: string;
  duration_sec: number;
  format_info: MovieFormatInfo;
  id: string;
  playback_speed_ratio: number;
  server_id: string;
  timestamp: Timestamp;
  skip?: boolean;
  source?: string;
  src?: string;
  video_length?: number;
};

export type MovieFormatInfo = {
  codec_name: string;
  movie_hash: string;
  profile: string;
  frame_periodicity?: number;
  real_periodicity?: number;
  avg_frame_rate: string; // a string fraction
};

export type Timestamp = {
  meta_class: string;
  date: string;
};

export type Dimensions = {
  width: number;
  height: number;
  units?: string;
};

export type VideoInfo = {
  cameraName: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  linkHref: string;
  userAlias: string;
  totalVideoLength: number;
};

export type Location = {
  lat: number;
  lng: number;
};

export type Camera = {
  actual_values: any;
  address_components: any[];
  camera_group_ids: any[];
  camera_id: string;
  capabilities: string[];
  device_id_internal: string;
  is_online: boolean;
  labels: string[];
  local_camera_id: string;
  location: Location;
  mac_address: string;
  name: string;
  recent_uploads: boolean;
  resolution: {
    aspect_ratio: number;
    height: number;
    width: number;
  };
  rtsp_url_params: { [key: string]: string };
  rtsp_url_pattern: string;
  tags: string[];
  user_agent: string;
  user_id: string;
  zones: any[];
};

export type QueueInteractionFunction = (
  action_type: string,
  bucket_id: string,
  lazy: boolean,
  priorityRequested: number
) => void;

export type CameraNameClickFactoryFunction = (
  termToAppend: string,
  // @default true
  shouldSearch?: boolean,
  // @default false
  isCamera?: boolean,
  // @default undefined
  userId?: string
) => (event: Event) => void;
