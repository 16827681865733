
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";

@Component({})
export default class TheVideoPlayer extends Vue {
  // ---------- Props ----------
  /** The options (including source) for the video players. */
  @Prop({ default: {} }) options!: VideoJsPlayerOptions;

  /** Show the cursor as default or as panning crosshair */
  @Prop() cursor!: string;

  /** Check whether or not a paused video is because of a user action or the video player itself. */
  @Prop() userPausedVideo!: boolean;

  /** The aspect ratio of the video. */
  @Prop() aspectRatio!: number;

  /** Whether or not the viewer is in restricted mode. */
  @Prop() isViewerRestricted!: boolean;

  /** The width of the video player. */
  @Prop() playerWidth!: number;

  /** The height of the video player. */
  @Prop() playerHeight!: number;

  /** A changing time that tells us that the player needs to seek to a specific time. */
  @Prop() seekTime!: { newTime?: number };

  // ------- Local Vars --------
  /** An array of players. */
  players: VideoJsPlayer[] = [];

  /** The index in the above players array of the actively playing player (the one in front). */
  activePlayerIndex = 0;

  /** The index of the player that is in the background (the one that will be swapped). */
  swapPlayerIndex = 1;

  /** Used for counting the number of clicks to see if it was a doubleclick */
  clickCount = 0;

  /** Set the timeout to determine when something is a double vs single click. */
  doubleClickTimeout!: ReturnType<typeof setTimeout>;

  /** The timeout for determining when a mousedown/mouseup event set should be considered as a click. */
  isClickTimeout!: ReturnType<typeof setTimeout>;

  /** Boolean to consider an event as a click or not */
  isClickEvent = false;

  /** Boolean to help us keep track of if a click event was a drag event or not. */
  hasDragged = false;

  /** A queue to keep track of the sources coming in.  */
  sourceQueue: videojs.Tech.SourceObject[] = [];

  /** Keep track if we are dequeuing or if we need to restart. */
  dequeuing = false;

  /** Sometimes, seeked doesn't fire because the video is invalid. In that case, timeout */
  awaitSeekingTimeoutMs = 2000;

  // --------- Watchers --------
  @Watch("sourceQueue")
  async sourceQueueUpdate(): Promise<void> {
    // This is simple, basically, for each video that comes in, we want things to happen in order
    // so, every time options updates, push to queue. We work our way
    // through that queue in order to ensure that each swap happens in order
    if (!this.dequeuing) {
      this.dequeuing = true;
      while (this.sourceQueue.length > 0) {
        const source = this.sourceQueue.pop() as videojs.Tech.SourceObject;
        await this.dequeue(source);
      }
      this.dequeuing = false;
    }
    // otherwise, just exit
  }

  @Watch("options", { deep: true })
  updateOptions(): void {
    // Add a loading spinner to the active player if it is the first one
    if (this.players[this.activePlayerIndex].src() === "") {
      this.players[this.activePlayerIndex].addClass("vjs-waiting");
    }

    // Always try to play the video
    if (!this.userPausedVideo) {
      const promise = this.players[this.activePlayerIndex].play();
      if (promise !== undefined) {
        promise.catch(() => null);
      }
    }

    // initially, this is empty, so we only want to run this logic when it has a source.
    if (this.options.sources && this.options.sources.length > 0) {
      // Note that the way it works right now, we replace the array every time, so it always has just 1 element in it.
      this.sourceQueue.push(this.options.sources[0]);
    }
  }

  @Watch("seekTime", { deep: true })
  goToTime() {
    if (this.seekTime.newTime) {
      // Watches for changes to this value... essentially that tells us that it's time to seek to a different point in time in the video
      this.players[this.activePlayerIndex].currentTime(this.seekTime.newTime);
    }
  }
  // ------- Lifecycle ---------

  mounted(): void {
    // Called when the component is mounted on a page

    // Initialize the two videojs players and put them in an array for easy access
    [this.activePlayerIndex, this.swapPlayerIndex].forEach((playerIndex) => {
      this.players.push(
        videojs(
          this.$refs[`videoPlayer${playerIndex}`] as Element,
          this.options,
          () => {
            const player = this.players[playerIndex];
            if (this.isViewerRestricted === false) {
              const child = player.controlBar.addChild("DownloadButton", {});
              (child.el() as HTMLButtonElement).title = "Download Video";
            }
            player.playsinline(true);
            const playPromise = player.play();
            if (playPromise !== undefined) {
              playPromise.catch((error) => {
                // console.log("Play was prevented", error);
              });
            }
          }
        )
      );
    });

    this.listenToPauseButtonClicks(this.players);

    // Let our parent know that the players have been created
    this.$emit("players-made", {
      playerList: this.players,
      pannablePlayer: this.$refs.pannablePlayer,
      activePlayer: this.activePlayerIndex,
      inactivePlayer: this.swapPlayerIndex,
    });
  }

  beforeDestroy(): void {
    this.players.forEach((player) => {
      if (player) {
        player.dispose();
      }
    });
  }

  // --------- Methods ---------

  /** Handles async dequeuing of videos in strictly the order they were recieved. */
  async dequeue(source: videojs.Tech.SourceObject): Promise<void> {
    return new Promise((resolve) => {
      // Get rid of any pending events
      this.players[this.activePlayerIndex].off([
        "playing",
        "error",
        "seeking",
        "seeked",
      ]);
      this.players[this.swapPlayerIndex].off([
        "playing",
        "error",
        "seeking",
        "seeked",
      ]);

      // If the user closes everything too fast, we end up with a race condition where the
      // video player is already destroyed, but we are still dequeuing. In that case, we just catch
      // the error and pretend like it was a sucess so we don't block anything else.
      try {
        // Check for the swap player index to be in the playing state before swapping to minimize loading glitches.
        this.players[this.swapPlayerIndex].one("playing", () => {
          // The video cannot be seeked if it is a 404 (which means seeked never fires)
          // we need to time out in that case because otherwise we get stuck.
          const seekingTimeout = setTimeout(() => {
            // if failure, just resolve so we move on to the next video in the queue
            // Note: it is up to fetch.ts to determine if the video is unfetchable, NOT this code
            resolve();
          }, this.awaitSeekingTimeoutMs);

          this.queuePlayerSwap(() => {
            clearTimeout(seekingTimeout);
            resolve();
          });
        });

        // listen for error events
        this.players[this.swapPlayerIndex].one("error", (e: any) => {
          const player = e.target.player as VideoJsPlayer;
          // stop listening for the play event on this player
          player.off("playing");

          // This is the case where we haven't loaded a video yet. In that
          // scenario, we want to swap, but we want the visible player to show loading
          // for the hidden player.
          if (!this.players[this.activePlayerIndex].hasStarted()) {
            // The video cannot be seeked if it is a 404 (which means seeked never fires)
            // we need to time out in that case because otherwise we get stuck.
            const seekingTimeout = setTimeout(() => {
              // if failure, just resolve so we move on to the next video in the queue
              // Note: it is up to fetch.ts to determine if the video is unfetchable, NOT this code
              resolve();
            }, this.awaitSeekingTimeoutMs);

            this.queuePlayerSwap(() => {
              clearTimeout(seekingTimeout);
              resolve();
            });

            this.players[this.swapPlayerIndex].addClass("vjs-seeking");
            this.players[this.activePlayerIndex].one("timeupdate", () => {
              this.players[this.activePlayerIndex].addClass("vjs-waiting");
            });
          }
        });
        // Set the src to the new incoming source value and load and play it
        this.players[this.swapPlayerIndex].src(source);
        this.players[this.swapPlayerIndex].load();
        const playPromise = this.players[this.swapPlayerIndex].play();

        // catch play interrupted by load error (https://developers.google.com/web/updates/2017/06/play-request-was-interrupted)
        if (playPromise !== undefined) {
          playPromise.catch(() => {
            // console.log("Play was prevented.");
          });
        }

        // Let the parent know that the players were made
        this.$emit("players-made", {
          playerList: this.players,
          activePlayer: this.swapPlayerIndex,
          inactivePlayer: this.activePlayerIndex,
          pannablePlayer: this.$refs.pannablePlayer,
        });
      } catch (err) {
        console.warn("Caught error in video player queueing process.");
        console.log(err);
        resolve();
      }
    });
  }

  /** Function for handling the swap of a playing player with a non-playing player.
   * Note: Calling this function means the swap will complete EVENTUALLY. The
   * doneSeeking function will be called once the swap has completed.
   */
  queuePlayerSwap(doneSeekingFcn?: () => void): void {
    const ESTIMATED_DELAY_SEC = (window as any).VIEWER_DELAY_OFFSET || 0.2;

    // Fixes jumpy video
    this.players[this.swapPlayerIndex].one("seeked", () => {
      // show the new player
      this.players[this.swapPlayerIndex].show();

      // hide the active player
      this.players[this.activePlayerIndex].pause();
      this.players[this.activePlayerIndex].hide();

      // change the indices
      let newSwap = this.activePlayerIndex;
      this.activePlayerIndex = this.swapPlayerIndex;
      this.swapPlayerIndex = newSwap;

      // If it was paused before, it should stay that way
      if (this.userPausedVideo) {
        this.players[this.activePlayerIndex].pause();
      }
      this.ensureCorrectControlBarState();
      if (doneSeekingFcn) {
        doneSeekingFcn();
      }
    });

    // get the furthest player's time and use that
    let currentTime = Math.max(
      this.players[this.activePlayerIndex].currentTime(),
      this.players[this.swapPlayerIndex].currentTime()
    );

    this.players[this.swapPlayerIndex].currentTime(
      currentTime + ESTIMATED_DELAY_SEC
    );
  }

  /** Toggle the player that was clicked to play or pause */
  togglePlay(event: Event): void {
    const player: VideoJsPlayer =
      event.target && (event.target as any).player
        ? (event.target as any).player
        : undefined;

    if (player) {
      if (player.paused()) {
        player.play();
        this.$emit("userPaused", false);
      } else {
        player.pause();
        this.$emit("userPaused", true);
      }
    }
    this.ensureCorrectControlBarState();
  }

  /** Check whether the click event that was registered earlier was a double click (350 ms)
   * (for whatever reason @dblclick doesn't fire, so this has to do)
   */
  checkClick(event: Event): void {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.doubleClickTimeout = setTimeout(() => {
        this.clickCount = 0;
        this.togglePlay(event);
      }, 350);
    } else {
      // it's a double click, so ignore
      clearTimeout(this.doubleClickTimeout);
      this.clickCount = 0;
    }

    this.ensureCorrectControlBarState();
  }

  /** Starts a timer to check whether a click or a pan */
  startTimer(): void {
    this.isClickEvent = true;
    this.isClickTimeout = setTimeout(() => {
      this.isClickEvent = false;
    }, 200);
  }

  /** Checks the timer between mouseup and down events to see if the click was
   * held in past a certain amount of time.
   */
  checkTimer(event: Event): void {
    clearTimeout(this.isClickTimeout);
    if (this.isClickEvent) {
      this.checkClick(event);
    }
    // not a click event, so probably a pan, ignore it
  }

  /** Annoyingly have to do this because for some reason, the control bar's state gets out of wack (shows pause when playing) */
  ensureCorrectControlBarState(): void {
    const playToggleActive = (
      this.players[this.activePlayerIndex].controlBar as any
    ).playToggle;
    const playToggleInactive = (
      this.players[this.swapPlayerIndex].controlBar as any
    ).playToggle;

    if (this.players[this.activePlayerIndex].paused()) {
      playToggleActive.addClass("vjs-paused");
      playToggleActive.removeClass("vjs-playing");
    } else {
      playToggleActive.removeClass("vjs-paused");
      playToggleActive.addClass("vjs-playing");
    }

    if (this.players[this.activePlayerIndex].paused()) {
      playToggleInactive.removeClass("vjs-playing");
      playToggleInactive.addClass("vjs-paused");
    } else {
      playToggleInactive.addClass("vjs-playing");
      playToggleInactive.removeClass("vjs-paused");
    }
  }

  /** Keeps track of the pause/play button clicks to update our userPaused state. */
  listenToPauseButtonClicks(videoPlayersToSubscribeTo: VideoJsPlayer[]): void {
    videoPlayersToSubscribeTo.forEach((player) => {
      const playButton = player.controlBar
        .children()
        .find((child: any) => child.hasClass("vjs-play-control"));
      if (playButton) {
        playButton.on("click", () => {
          if (playButton.player().paused()) {
            this.$emit("userPaused", true);
          } else {
            this.$emit("userPaused", false);
          }
        });
      } else {
        console.log("No play button found");
      }
    });
  }

  /** Since touchstart doesn't seem to work, we check the touchend + dragged
   * event combo + drag to keep track of when a touch should pause/play the video. */
  checkTouchEnd(event: Event) {
    // if hasDragged is true, then we shouldn't toggle play state
    if (!this.hasDragged) {
      // If this wasn't a drag event, just immediately start
      this.togglePlay(event);
    }

    this.hasDragged = false;
  }
}
