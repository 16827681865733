
import { initPopover } from "@/plugins/bootstrapSetup";
import { VideoJsPlayer } from "video.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TheFrameByFrameAdvanceButtons extends Vue {
  // ---------- Props ----------

  /** The video player element to advance frame-by-frame on */
  @Prop() activePlayer!: VideoJsPlayer;

  /** The framerate of the video if it exists (otherwise it's 30 fps) */
  @Prop() framerate!: number;

  /** The framerate of the video if it exists (otherwise it's 30 fps) */
  @Prop() userHasPaused!: boolean;

  // ------- Local Vars --------

  /** Only shows the buttons when the video is paused. */
  showButtons = false;

  /** Defines how big the step in frame should be. */
  VIDEO_STEP = 1;

  // --------- Watchers --------

  @Watch("userHasPaused")
  updateButtonVisibility(): void {
    this.toggleFrameByFrame();
  }
  // ------- Lifecycle ---------
  beforeDestroy(): void {
    // clean up when destroyed
    window.removeEventListener("keyup", this.handleKeyUpEvent);
  }

  mounted(): void {
    if (this.$refs.fbfButtons) {
      initPopover(this.$refs.fbfButtons as Element, undefined, false);
    }
  }

  // --------- Methods ---------
  /** Sets up (or tears down) the frame-by-frame keybindings and button visibility. */
  toggleFrameByFrame(): void {
    if (this.userHasPaused) {
      window.addEventListener("keyup", this.handleKeyUpEvent);
      this.showButtons = true;
    } else {
      window.removeEventListener("keyup", this.handleKeyUpEvent);
      this.showButtons = false;
    }
  }

  /** Handles the keypress event for advancing frames */
  handleKeyUpEvent(e: KeyboardEvent): void {
    const element = e.target as HTMLElement;
    // Ignore any keypresses that are in input fields and stuff
    if (element.nodeName === "BODY") {
      if (e.key == "]") {
        this.advanceFrame(1);
      } else if (e.key == "[") {
        this.advanceFrame(-1);
      }
    }
  }

  /** Advance the video frames. */
  advanceFrame(direction: number): void {
    const currentTime = this.activePlayer.currentTime();
    const moveBy = (direction * this.VIDEO_STEP) / this.framerate;
    this.activePlayer.currentTime(currentTime + moveBy);
  }
}
