
import { calculateSizeWithAspectRatio } from "@/helpers";
import { Camera } from "@/types";
import { VideoJsPlayer } from "video.js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TheRealVideoDimensionsReferenceOverlay extends Vue {
  // ---------- Props ----------

  /** The camera and all it's metadata corresponding to this event. */
  @Prop() eventCamera!: Camera | null;

  /** The aspect ratio of the video. */
  @Prop() videoAspectRatio!: number;

  /** The aspect ratio of the video player. */
  @Prop() playerAspectRatio!: number;

  /** Both possible videojs players... this is empty initially, then has length two when the players are initialized. */
  @Prop() players!: VideoJsPlayer[] | [];

  /** The width of the video player. */
  @Prop() playerWidth!: number;

  /** The height of the video player. */
  @Prop() playerHeight!: number;

  // ------- Local Vars --------

  /** The ideal size of the map overlay (initial value) */
  INITIAL_DIMEN = { width: 100, height: 100, offsetTop: 0, offsetLeft: 0 };

  /** The ideal size of the map overlay */
  idealSize = Object.entries(this.INITIAL_DIMEN).reduce((prev, curr) => {
    const [key, value]: [keyof typeof prev, number] = curr;
    prev[key] = value + "%";
    return prev;
  }, {} as { [key: string]: string });

  // --------- Watchers --------

  // ------- Lifecycle ---------
  created(): void {
    window.addEventListener("resize", this.calcVideoSize);
    // Need to wait for vjs players to set up
    setTimeout(() => {
      this.calcVideoSize();
    }, 1000);
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.calcVideoSize);
  }

  // --------- Methods ---------

  /** Calculates the dimensions of the video (width + height) */
  calcVideoSize() {
    const { width, height } = calculateSizeWithAspectRatio(
      this.videoAspectRatio
    );

    const calculatedWidth = width;
    const calculatedHeight = height;
    let realWidth = 0;
    let realHeight = 0;
    let offsetLeft = 0;
    let offsetTop = 0;

    // Check if the calculated values are out of bounds of the actual max width or height of the player.
    if (calculatedWidth >= this.playerWidth) {
      // This means the width is our constraining factor, so our height is scaled down by the same ratio
      realWidth = this.playerWidth;
      realHeight = calculatedHeight;

      // In this case, since width is constraining factor, and we can assume the player will always
      // center the video, the offset is going to be the (player height - video height) / 2
      offsetTop = (this.playerHeight - calculatedHeight) / 2;

      this.idealSize = {
        width: realWidth + "px",
        height: realHeight + "px",
        offsetTop: offsetTop + "px",
        offsetLeft: offsetLeft + "px",
      };
    } else if (calculatedHeight >= this.playerHeight) {
      // This means the height is our constraining factor, so our width is scaled down by the same ratio
      realHeight = this.playerHeight;
      realWidth = calculatedWidth;

      // In this case, since height is constraining factor, and we can assume the player will always
      // center the video, the offset is going to be the (player width - video width) / 2
      offsetLeft = (this.playerWidth - calculatedWidth) / 2;

      this.idealSize = {
        width: realWidth + "px",
        height: realHeight + "px",
        offsetTop: offsetTop + "px",
        offsetLeft: offsetLeft + "px",
      };
    } else {
      console.warn(
        "We shouldn't hit the case where the video is overall smaller than the player!"
      );
    }
  }
}
