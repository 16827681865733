
import { initPopover } from "@/plugins/bootstrapSetup";
import { Bucket, QueueInteractionFunction } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { global } from "@/fetch";
@Component({})
export default class TheUrgentUploadOverlay extends Vue {
  // ---------- Props ----------

  /** A function for making urgent upload calls. */
  @Prop() queueInteraction!: QueueInteractionFunction;

  /** The current bucket the viewer is using. */
  @Prop() currentBucket!: Bucket;

  // ------- Local Vars --------

  /** The interval that is set up to check the timestamp */
  intervalId!: ReturnType<typeof setInterval>;

  /** If at least one of the videos is missing. */
  atLeastOneVideoMissing = false;

  /** If the video is marked as important (after clicking button). */
  videoMarkedAsImportant = false;
  // --------- Watchers --------

  // ------- Lifecycle ---------
  mounted(): void {
    if (this.$refs.videoMissingButton) {
      initPopover(this.$refs.videoMissingButton as Element);
    }

    // set up an interval to update the timestamp every second
    this.intervalId = setInterval(() => {
      this.checkIfVideoMissing();
    }, 500);
  }

  destroyed(): void {
    clearInterval(this.intervalId);
  }

  // --------- Methods ---------

  /** Used to continually check if the video is missing from the local state. We have to do it this way in an interval bc the property isn't reactive bc of how I implemented this originally, whoops. */
  checkIfVideoMissing() {
    this.atLeastOneVideoMissing = global.atLeastOneVideoMissing;
  }

  /** Tells the box to queue the videos for upload using the queueInteraction function */
  urgentlyFetchVideo() {
    if (this.currentBucket) {
      this.queueInteraction("play", this.currentBucket.bucket_id, false, 82);
      this.videoMarkedAsImportant = true;
    } else {
      console.error("Failed to find current bucket.");
    }
  }
}
