
import { initPopover } from "@/plugins/bootstrapSetup";
import { Popover } from "bootstrap";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  extractScreenshot,
  downloadFromSrc,
  makeSafeFileName,
} from "@/helpers";
import { VideoJsPlayer } from "video.js";
import { VideoInfo } from "@/types";

@Component({})
export default class TheAccessControlToggleButton extends Vue {
  // ---------- Props ----------

  /** The currently active player (since the players swap, this changes as videos come in) */
  @Prop() activePlayer!: VideoJsPlayer;

  /** The video info defined in the camio_viewer.js file. */
  @Prop() videoInfo!: VideoInfo;

  /** Whether or not the viewer is in restricted mode. */
  @Prop() isViewerRestricted!: boolean | undefined;

  // ------- Local Vars --------

  /** Reference to all popovers so we can modify them (Note that in this component, there is only one). */
  popover!: Popover[];

  // --------- Watchers --------

  // ------- Lifecycle ---------

  mounted(): void {
    if (this.$refs.screenshotButton) {
      this.popover = initPopover(
        this.$refs.screenshotButton as Element,
        undefined,
        false
      );
    }
    window.addEventListener("keyup", this.handleKeyUpEvent);
  }

  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUpEvent);
  }
  // --------- Methods ---------

  /** Choose an icon based on the state. */
  get chooseIcon(): string[] {
    return ["camera"];
  }

  /** Gets the help message. */
  get dynamicHelpMessage(): string {
    return "Screenshot the current frame.";
  }

  /** Saves the screenshot using helper functions in helpers.ts */
  saveScreenshot(): void {
    // Only save if it is not a restricted viewer
    if (this.isViewerRestricted === false) {
      const videoElement = this.activePlayer.el()
        .children[0] as HTMLVideoElement;
      const currentTime = this.videoInfo.startDate.add(
        this.activePlayer.currentTime(),
        "seconds"
      );
      const fileName = makeSafeFileName(
        `screenshot-${this.videoInfo.cameraName}-${this.videoInfo.userAlias}-${currentTime}`
      );

      const screenshotBase64 = extractScreenshot(videoElement, "png") as string;

      downloadFromSrc("png", fileName, screenshotBase64);
    }
  }

  /** Handles the keypress event for advancing frames */
  handleKeyUpEvent(e: KeyboardEvent): void {
    const element = e.target as HTMLElement;
    // Ignore any keypresses that are in input fields and stuff
    if (element.nodeName === "BODY") {
      if (e.key == "s") {
        this.saveScreenshot();
      }
    }
  }
}
