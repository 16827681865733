import { makeSafeFileName } from "../helpers";
import videojs from "video.js";
import { downloadFromSrc } from "@/helpers";

const vjsButton = videojs.getComponent("Button");

class DownloadButton extends vjsButton {
  handleClick() {
    const player = this.player();
    const src = player.currentSrc();
    downloadFromSrc("mp4", makeSafeFileName(`camio-video-${new Date()}`), src);
  }

  buildCSSClass() {
    return "vjs-control vjs-download-button";
  }

  createControlTextEl(button: HTMLButtonElement) {
    button.children[0].innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" stroke="white" stroke-width="1.25" stroke-width="10" class="bi bi-download" viewBox="0 0 16 16">
    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg>`;
    return button;
  }
}

videojs.registerComponent("DownloadButton", DownloadButton);
