
import { Component, Vue, Watch } from "vue-property-decorator";
import { cleanUpFetcher, fetchVideoAndConcatenate } from "./fetch";
import { VideoJsPlayerOptions } from "video.js";
import TheVideoPlayerContainer from "@/components/TheVideoPlayerContainer.vue";
import dummyCamera from "./dummy-values/camera.dummy.json";
import videoSources from "./dummy-values/video-sources.dummy.json";
import {
  Camera,
  CameraNameClickFactoryFunction,
  DownloadProgress,
  PlayerTypes,
  QueueInteractionFunction,
  VideoInfo,
  VideoSource,
} from "@/types";

import dayjs from "dayjs";

@Component({
  components: {
    TheVideoPlayerContainer,
  },
})
export default class CamioViewer extends Vue {
  // ------- Local Vars --------
  /** Test urls for running when in debug mode */
  TEST_URL =
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4";
  // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";
  TEST_BAD_URL_404 =
    "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream";

  /** Aspect ratio for the player is not necessarily the same as the video.  */
  DEFAULT_ASPECT_RATIO_PLAYER = 1.77778; // 16:9 aspect ratio by default
  DEFAULT_ASPECT_RATIO_VIDEO = 1.77778; // 16:9 aspect ratio by default

  /** The video options that should be passed down to the videojs player. */
  videoOptions: VideoJsPlayerOptions = {
    autoplay: true,
    controls: true,
    muted: true,
    bigPlayButton: false,
    loop: true,
    preload: "metadata",
    fill: true,
    userActions: {
      doubleClick: false,
    },
  };

  /** Whether or not to show the panzoom overlay, set to none, in the future, can be other overlays */
  showOverlay = PlayerTypes.NONE;

  /** An array of blobs returned from the fetch function with the last index as the most recently combined url.  */
  blobArray: string[] = [];

  /** This is purely so we don't have to write this typecast every time. */
  noTypeWindow = window as any;

  /** The camioPlayerWidget from the old camio viewer files in camio_viewer.js. (we need to do this check because it needs to be a clone) */
  camioPlayerWidget =
    Object.keys({ ...this.noTypeWindow.camioPlayerWidget }).length === 0
      ? false
      : { ...this.noTypeWindow.camioPlayerWidget };

  /** The video sources with metadata (fetched globally from the camio_viewer.js file) */
  videoSourcesWithMetaData: VideoSource[] = [];

  /** The sources for the videos fetched from the camio_viewer.js file */
  playerSources: string[] = [];

  /** The framerate of the video in frames/second (defaults to 30 if no framerate available) */
  videoFramerate = 30;

  /** Gets the played movies with the bucket id so we know if we can skip. */
  playedMovies: { [movieId: string]: string } = {};

  /** Camera for this event. */
  eventCamera: Camera | null = null;

  /** Represents the download progress of all the videos */
  downloadProgress: DownloadProgress = {};

  /** Represents the special case where all the videos have been skipped and we need to show a special state. */
  allVideosSkipped = false;

  /** Will be false if the global variable shows the viewer as not restricted. */
  isViewerRestricted = true;

  /** The current query that is in the search bar. */
  currentQuery = "";

  /** A function for making urgent upload calls. */
  queueInteraction: QueueInteractionFunction = (
    action_type: string,
    bucket_id: string,
    lazy: boolean,
    priorityRequested: number
  ) => {
    action_type;
    bucket_id;
    lazy;
    priorityRequested;
    return null;
  };

  /** An outside function for performing a search with a selected label. */
  cameraNameClickFactory!: CameraNameClickFactoryFunction;

  // --------- Watchers --------
  @Watch("blobArray")
  updateToBlob(): void {
    if (this.blobArray.length !== 0) {
      // grab the latest blob that was pushed (reason this function is running) from the fetch function
      const blob = this.blobArray[this.blobArray.length - 1];

      const newSource = {
        src: blob,
        type: "video/mp4",
      };

      // add the source onto the video options as a new object so we trigger reactivity
      this.videoOptions = {
        ...this.videoOptions,
        ...{
          sources: [newSource],
        },
      };

      // turn off the loader, info, and controls if they are showing because we don't need them anymore.
      if (this.camioPlayerWidget) {
        this.camioPlayerWidget.loaderOff();
        this.camioPlayerWidget.controls.hide();
        this.camioPlayerWidget.info.hide();
      }
    }
  }

  // ------- Lifecycle ---------
  created(): void {
    // Called when a component is initialized
    // TODO: add this back in when we want it, the next two lines are temporary for now.
    // window.addEventListener("keyup", this.togglePanzoom);
    this.showOverlay = PlayerTypes.PANZOOM;

    if (
      this.noTypeWindow.connector &&
      this.noTypeWindow.connector.state &&
      this.noTypeWindow.connector.state.q
    ) {
      this.currentQuery = this.noTypeWindow.connector.state.q;
    }
    // Initialize the camioPlayerWidget from camio_viewer.js if it's not on the window and we have a connector, then it must use "widget"
    // as it's global name instead of camioPlayerWidget (so lets make sure it exists as such)
    if (!this.noTypeWindow.camioPlayerWidget && this.noTypeWindow.connector) {
      this.camioPlayerWidget = { ...this.noTypeWindow.widget };
    }

    // If the camioPlayerWidget from camio_viewer.js exists now that we have initialized it
    if (this.camioPlayerWidget) {
      // TODO: refactor to just use videosourceswithmetadata since it is a fuller picture. Need to remove from camio_viewer.js too
      // Grab just the player sources as an array of strings
      if (this.camioPlayerWidget.convertedVideoSources) {
        this.playerSources = this.camioPlayerWidget
          .convertedVideoSources as string[];
      }

      // Grab all of the video metadata
      if (this.camioPlayerWidget.video_sources) {
        this.videoSourcesWithMetaData = this.camioPlayerWidget
          .video_sources as VideoSource[];
      }

      // get the framerate
      if (this.videoSourcesWithMetaData.length > 0) {
        // this is just an array of identical framerates, choose the first one
        const framerate = this.videoSourcesWithMetaData
          .map((videoSrc) => {
            // if this fails, just use the fps declared earlier
            if (videoSrc.bucket && videoSrc.bucket.movies.length > 0) {
              const moviesFPS = videoSrc.bucket.movies
                .map((movie) => {
                  // If we have an average framerate, return that, otherwise return 0
                  if (movie.format_info && movie.format_info.avg_frame_rate) {
                    let matched = /^([\d]+)\/([\d]+)$/g.exec(
                      movie.format_info.avg_frame_rate
                    );
                    if (matched) {
                      const result = Number(matched[1]) / Number(matched[2]);
                      // If we have correctly parsed the string and there wasn't any funny business, then we should use it
                      if (isNaN(result)) {
                        return 0;
                      } else {
                        return result;
                      }
                    } else {
                      return 0;
                    }
                  } else {
                    return 0;
                  }
                })
                .filter((frameRates) => frameRates !== 0);
              // note: sometimes all framerates get filtered out,
              // by selecting the default value for framerate, we
              // ensure we have at least one valid framerate
              const fpsWithDefault = moviesFPS.concat(this.videoFramerate);
              // We might have cameras that have varying framerates based on motion.
              // the design decision here is to choose the highest framerate one (the one with motion)
              // at the risk of putting the low framerate video at hyperspeed (this is probably okay because
              // the framerate is likely low because nothing important is happening)
              return Math.max(...fpsWithDefault);
            }
          })
          .filter((definedAvg): definedAvg is number => !!definedAvg);

        if (framerate && framerate.length > 0) {
          // again, select the maximum framerate
          this.videoFramerate = Math.max(...framerate);
        }
      }

      // get the played movies by bucket id so we know how to filter out duplicate videos across events.
      if (this.camioPlayerWidget.playedMovies) {
        this.playedMovies = this.camioPlayerWidget.playedMovies;

        // filter out any sources that shouldn't be played
        this.playerSources = this.playerSources.filter(
          (_, index) => !this.videoSourcesWithMetaData[index].skip
        );
        this.videoSourcesWithMetaData = this.videoSourcesWithMetaData.filter(
          (_, index) => !this.videoSourcesWithMetaData[index].skip
        );

        // mark all our current sources as played so we don't repeat videos across events
        this.videoSourcesWithMetaData.forEach((source) => {
          if (source.bucket?.bucket_id != this.playedMovies[source.movie.id]) {
            this.playedMovies[source.movie.id] = source.bucket
              ? source.bucket.bucket_id
              : "";
          }
        });
      }

      // Fetch the cameras for this user if possible.
      if (
        this.camioPlayerWidget.connector &&
        this.camioPlayerWidget.connector.accountInfo &&
        this.camioPlayerWidget.connector.userInfo &&
        this.videoSourcesWithMetaData.length > 0
      ) {
        const accountInfo = this.camioPlayerWidget.connector.accountInfo;
        const userInfo = this.camioPlayerWidget.connector.userInfo;
        const bucket = this.videoSourcesWithMetaData[0].bucket;

        let fetchedCameras: Camera[] = [];
        if (userInfo && userInfo.cameras) {
          fetchedCameras = fetchedCameras.concat(userInfo.cameras);
        }
        if (
          accountInfo.users[userInfo.selected_email] &&
          accountInfo.users[userInfo.selected_email].cameras
        ) {
          fetchedCameras = fetchedCameras.concat(
            accountInfo.users[userInfo.selected_email].cameras
          );
        }

        // To address later: would be the case of shared events
        // else if (accountInfo.camios["TODO: TOKEN"]) {
        //   // get the token from somewhere
        //   fetchedCameras = accountInfo.camios["TODO: TOKEN"].cameras;
        // }

        const camFromEvent = fetchedCameras.find((camera) => {
          return (
            camera.user_id === bucket?.user_id && camera.name === bucket.source
          );
        });
        if (camFromEvent) {
          this.eventCamera = camFromEvent;
        }
      }
    } else {
      // Somehow, something else went wrong (or we are in debug mode) so let us know
      console.warn("The player widget doesn't exist on window!!");
    }

    // Get the queue interaction function from the uiEventHandler to use for
    // telling the box to fetch videos
    if (this.noTypeWindow.uiEventHandler) {
      this.queueInteraction = this.noTypeWindow.uiEventHandler.queueInteraction;
      this.cameraNameClickFactory =
        this.noTypeWindow.uiEventHandler.cameraNameClickFactory;
    }

    // If in debug mode, use test urls
    if (this.noTypeWindow.DEBUG_NEW_VIEWER) {
      console.warn("In debug mode, using sample urls!");
      this.playerSources = [
        // "http://localhost:3000/super_tall_vid_sideways.mp4",
        // this.TEST_BAD_URL_404,
        // this.TEST_BAD_URL_404,
        // this.TEST_URL,
        // this.TEST_URL,
        // this.TEST_URL,
        this.TEST_URL,
        // this.TEST_URL,
        // this.TEST_URL,
        // this.TEST_BAD_URL_404,
        // this.TEST_URL,
      ];

      // If the event camera was not populated, then we should populate it with dummy data.
      if (!this.eventCamera) {
        this.eventCamera = dummyCamera;
      }

      if (this.videoSourcesWithMetaData.length <= 0) {
        this.videoSourcesWithMetaData = videoSources as VideoSource[];
      }
    }

    // There are cases where there are no player sources. In that case, immediately, return (to avoid errors and navigate to the next video)
    if (this.playerSources.length === 0) {
      this.allVideosSkipped = true;
      console.log("No video urls!");
    }

    // If there is a restricted viewer option available, update our value to match.
    if (this.noTypeWindow.GLOBAL_SETTINGS) {
      if ("is_restricted_viewer" in this.noTypeWindow.GLOBAL_SETTINGS) {
        this.isViewerRestricted =
          this.noTypeWindow.GLOBAL_SETTINGS.is_restricted_viewer;
      }
      // Note: we also have a `is_download_disabled` option,
      // but assuming restricted implies can't download
    }

    // Fetch the videos and concatenate together
    // this function will return a reference to the blobs, which will have new
    // information added to them as it becomes available.
    fetchVideoAndConcatenate(
      this.playerSources,
      this.videoLengthByVideo,
      this.isBucketMissingVideo,
      this.videoFramerate,
      this.progressUpdate
    )
      .then((blobs) => {
        // keep as a reference.. this will be updated as new urls come in.
        this.blobArray = blobs;
      })
      .catch((err) => {
        console.log("Something Went Wrong!!", err);
      });
  }

  beforeDestroy(): void {
    // TODO: add this back in when we want toggles to happen here instead of the old way.
    // window.removeEventListener("keyup", this.togglePanzoom);
    cleanUpFetcher();

    // destroy children
    this.$el.childNodes.forEach((node) => this.$el.removeChild(node));

    // Add the info back in because apparently the old viewer doesn't bother to do that
    if (this.camioPlayerWidget) {
      this.camioPlayerWidget.info.show();
    }
  }

  // --------- Methods ---------

  /** Returns the length of the videos as a map of (url, length of video). */
  get videoLengthByVideo(): Map<string, number> {
    const videos = new Map<string, number>();
    this.videoSourcesWithMetaData.map((videoData, index) => {
      videos.set(this.playerSources[index], videoData.video_length);
    });
    return videos;
  }

  /** Gets the total length of all the videos concatenated. */
  get totalVideoLength(): number {
    let len = 0;
    this.videoLengthByVideo.forEach((value) => {
      len += value;
    });
    return len;
  }

  /** If the bucket doesn't have videos, then don't try to fetch them (because they will never show up) */
  get isBucketMissingVideo(): boolean {
    if (
      this.camioPlayerWidget &&
      this.noTypeWindow.connector &&
      this.videoSourcesWithMetaData.length > 0
    ) {
      return this.noTypeWindow.connector.isBucketMissingVideo(
        this.videoSourcesWithMetaData[0].bucket?.bucket_id
      );
    } else {
      return false;
    }
  }

  /** Gets the video info to use in the tag that tells the user what camera they are looking at */
  get videoInfo(): VideoInfo | undefined {
    if (this.camioPlayerWidget && this.videoSourcesWithMetaData.length > 0) {
      const firstVidDateString = this.videoSourcesWithMetaData[0].date_created;

      // Grab the last date and add the video length (since the date_created
      // represents the first second of a video clip and we want the end)
      const lastIndex = this.videoSourcesWithMetaData.length - 1;
      const lastVidDateString =
        this.videoSourcesWithMetaData[lastIndex].date_created;
      const startDate = dayjs(firstVidDateString);
      let endDate = dayjs(lastVidDateString).add(
        this.totalVideoLength,
        "seconds"
      );

      return {
        cameraName: this.videoSourcesWithMetaData[0].source,
        startDate,
        endDate,
        totalVideoLength: this.totalVideoLength,
        userAlias: this.userAlias,
        linkHref: this.cameraLink,
      };
    } else if (this.noTypeWindow.DEBUG_NEW_VIEWER) {
      // we are in debug mode so show some dummy output
      const startDate = dayjs(videoSources[0].date_created);
      let endDate = dayjs(videoSources[0].date_created).add(
        this.totalVideoLength,
        "seconds"
      );
      return {
        cameraName: "Chrome Video",
        startDate,
        endDate,
        userAlias: this.userAlias,
        totalVideoLength: this.totalVideoLength,
        linkHref: this.cameraLink,
      };
    } else {
      // something just went wrong
      return undefined;
    }
  }

  /** Gets the Href for the camera name if the user wants to search. */
  get cameraLink(): string {
    if (
      this.camioPlayerWidget &&
      this.noTypeWindow.connector &&
      this.videoSourcesWithMetaData.length > 0
    ) {
      let base = "#search;q=";
      if (this.camioPlayerWidget.isViewer) {
        base = "/app/" + base;
      }
      const bucket = this.videoSourcesWithMetaData[0].bucket;
      let search = this.noTypeWindow.connector.state.q;
      // if the camera name is not in the query, then add it
      if (
        !this.noTypeWindow.connector.isCameraInQuery(bucket?.source, search)
      ) {
        search = this.noTypeWindow.connector.constrainQueryToCamera(
          bucket?.source,
          bucket?.user_id
        );
      }

      return `${base}${encodeURIComponent(search)}`;
    } else {
      // there is no player, so return an empty string (no href abilities)
      return "";
    }
  }

  /** Gets the user alias (typically email or name of user) to disambiguate similarly named cameras.  */
  get userAlias(): string {
    return this.camioPlayerWidget ? this.camioPlayerWidget.user_alias : "";
  }

  /** Gets the aspect ratio of the player */
  get getPlayerAspectRatio(): number {
    // As per discussion with carter, this solves design problem of
    // buttons not fitting at the bottom of the video for tall skinny videos
    return this.DEFAULT_ASPECT_RATIO_PLAYER;
  }

  /** Gets the aspect ratio of a video */
  get actualVideoRatio(): number {
    if (
      this.videoSourcesWithMetaData.length > 0 &&
      this.videoSourcesWithMetaData[0].bucket
    ) {
      return this.videoSourcesWithMetaData[0].bucket.aspect_ratio;
    } else {
      return this.DEFAULT_ASPECT_RATIO_VIDEO;
    }
  }

  /** Check if we are still loading videos. We know we are done when the length of the blob array we make of concatenated videos == the amount of original sources + 1 bc we always include the first source in the list b4 concatenation */
  get videosLoaded(): boolean {
    return this.playerSources.length + 1 === this.blobArray.length;
  }

  /** Toggles the panzoom on when we want this viewer to be permenant and the toggle to happen within here. */
  togglePanzoom(e: KeyboardEvent): void {
    if (e.key == "m") {
      if (this.showOverlay === "NONE") {
        this.showOverlay = PlayerTypes.PANZOOM;
      } else {
        this.showOverlay = PlayerTypes.NONE;
      }
    }
  }

  /** The callback used in the fetch function on progress updates. */
  progressUpdate(progress: DownloadProgress): void {
    // need to copy to retain reactivity
    this.downloadProgress = { ...progress };
  }
}
