
import { DownloadProgress, Percentages } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TheDownloadProgressBar extends Vue {
  // ---------- Props ----------
  /** The current progress of all of the video downloads */
  @Prop() downloadProgress!: DownloadProgress;

  /** Tells us if the videos are still loading. */
  @Prop() videosLoaded!: boolean;

  // ------- Local Vars --------

  /** Keeps track of timeout ids */
  timeouts: ReturnType<typeof setTimeout>[] = [];

  /** Hide the progress bar when loading is complete. */
  hideProgress = false;
  // --------- Watchers --------

  // ------- Lifecycle ---------

  // --------- Methods ---------

  /** Returns a percentage between 0 and 100 representing 50% download, 25% timescale processing, 25% concatenation processing. */
  get calculateProgressBarValue(): number {
    // For each video get the download percentage and multiply it by half.
    // if it is downloaded (i.e. value is 1), then
    // if it is completely processed, "processed" is true for all, then can hide in hideprogress function
    const barValue =
      Object.keys(this.downloadProgress).reduce((prevVal, currVal) => {
        let downloadPercentage = 0;
        let ffmpegPercentage = 0;

        if (this.downloadProgress[currVal]) {
          downloadPercentage = this.downloadProgress[currVal].downloadPercent;
          ffmpegPercentage =
            this.downloadProgress[currVal].ffmpegTimescalePercent * 0.5 +
            this.downloadProgress[currVal].ffmpegConcatPercent * 0.5;
        }
        return (
          prevVal + (downloadPercentage * 0.2 + ffmpegPercentage * 0.8) * 100
        );
      }, 0) / Object.keys(this.downloadProgress).length;

    this.updateHideState();

    return barValue;
  }

  /** Keeps track of which videos have been fully processed */
  get videosProcessed(): { [videoIndex: string]: boolean } {
    const videosProcessed: { [videoIndex: string]: boolean } = {};
    Object.keys(this.downloadProgress).forEach((progress) => {
      if (
        this.downloadProgress[progress].ffmpegTimescalePercent === 1 &&
        this.downloadProgress[progress].ffmpegConcatPercent === 1 &&
        this.downloadProgress[progress].downloadPercent === 1
      ) {
        videosProcessed[progress] = true;
      } else {
        videosProcessed[progress] = false;
      }
    });
    return videosProcessed;
  }

  /** Determines whether or not to hide the progress bar (if it is 100% loaded) */
  updateHideState(): void {
    let shouldHide = Object.keys(this.downloadProgress)
      .map((progress) => {
        return (
          // We do this since sometimes the percentages get stuck at .9999999, so this "rounds them to 1"
          1 - this.downloadProgress[progress].ffmpegTimescalePercent <= 0.001 &&
          1 - this.downloadProgress[progress].ffmpegConcatPercent <= 0.001 &&
          1 - this.downloadProgress[progress].downloadPercent <= 0.001
        );
      })
      .reduce((prevVal, currVal) => {
        return prevVal && currVal;
      }, true);

    // downloadProgress is initially empty, so this is to make sure it doesn't eval to true prematurely
    if (Object.keys(this.downloadProgress).length === 0) {
      shouldHide = false;
    }

    // Delay so experience isn't "jittery" for user
    if (shouldHide || this.videosLoaded) {
      const timeout = setTimeout(() => {
        this.hideProgress = true;
        this.timeouts.forEach((timeout) => clearTimeout(timeout));
        this.timeouts = [];
      }, 3000);
      this.timeouts.push(timeout);
    } else {
      const timeout = setTimeout(() => {
        this.hideProgress = false;
        this.timeouts.forEach((timeout) => clearTimeout(timeout));
        this.timeouts = [];
      }, 3000);
      this.timeouts.push(timeout);
    }
  }

  /** Returns a custom progress message to display in the bar. */
  get loadingString(): string {
    let indexBeingProcessed = 0;
    Object.keys(this.videosProcessed).forEach((key, index) => {
      if (this.videosProcessed[key] === true) {
        indexBeingProcessed = index + 1;
      }
    });

    let loadedOrRequested = "Loaded";
    Object.keys(this.downloadProgress).map((progress) => {
      // Check if we should say requested (we should say it even if only one video needs to be requested)
      if (this.downloadProgress[progress].videoMissing) {
        loadedOrRequested = "Requested";
      }
    });

    return `${loadedOrRequested} ${indexBeingProcessed} of ${
      Object.keys(this.videosProcessed).length
    }`;
  }
}
