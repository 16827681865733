
import { initPopover } from "@/plugins/bootstrapSetup";
import { Popover } from "bootstrap";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class TheAccessControlToggleButton extends Vue {
  // ---------- Props ----------

  /** Whether or not access control is showing */
  @Prop() showAccessControl!: boolean;

  /** Show the map only if the lat and lng is not 0 and all fields exist. */
  @Prop() canShowAtAll!: boolean;

  // ------- Local Vars --------

  /** Reference to all popovers so we can modify them (Note that in this component, there is only one). */
  popovers!: Popover[];

  // --------- Watchers --------

  @Watch("showAccessControl")
  setDynamicHelpMessage(): void {
    // Need to essentially delete and rebuild because otherwise popover content doesn't change
    if (this.popovers) {
      // NOTE: there is only one, but this is for robustness
      this.popovers.forEach((popover) => {
        popover.dispose();
      });
      this.popovers = initPopover(
        this.$refs.accessControlButton as Element,
        this.dynamicHelpMessage
      );
    }
  }
  // ------- Lifecycle ---------

  mounted(): void {
    if (this.$refs.accessControlButton) {
      this.popovers = initPopover(
        this.$refs.accessControlButton as Element,
        this.dynamicHelpMessage
      );
    }
  }
  // --------- Methods ---------

  /** Choose an icon based on the state. */
  get chooseIcon(): string[] {
    if (this.showAccessControl) {
      return ["door-open"];
    } else {
      return ["door-closed"];
    }
  }

  /** Gets the dynamic help message. */
  get dynamicHelpMessage(): string {
    if (this.showAccessControl) {
      return "Hide Access Control Events.";
    } else {
      return "Show Access Control Events.";
    }
  }
}
