
import { Camera, MapZoomLevels } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class TheGoogleMapsOverlay extends Vue {
  // ---------- Props ----------

  /** The camera and all it's metadata corresponding to this event. */
  @Prop() eventCamera!: Camera | null;

  /** The zoom level of the map (or off) */
  @Prop() mapZoomLevel!: MapZoomLevels;

  /** The aspect ratio of the video. */
  @Prop() aspectRatio!: number;

  /** Show the map only if the lat and lng is not 0 and all fields exist. */
  @Prop() showMap!: boolean;

  /** Show the map when hovering over viewer. */
  @Prop() hovering!: boolean;

  // ------- Local Vars --------

  /** The google maps key. */
  G_MAPS_KEY = "AIzaSyDd5debVBqP8dJCMVAf8ZilhdbssI8QOL0";

  /** The two zoom level options. */
  CLOSE_ZOOM_LEVEL = 15;
  FAR_ZOOM_LEVEL = 11;

  /** The ideal size of the map overlay (initial value) */
  INITIAL_DIMEN = { width: 350, height: 200 };

  /** The ideal size of the map overlay */
  idealSize = this.INITIAL_DIMEN;

  /** Used so we can set a timeout on local hover changes so things aren't so abrupt. */
  localHoverForTimeout = false;

  /** Wait before changing the hover state to false */
  HOVER_TIMEOUT = 2000;

  // --------- Watchers --------
  @Watch("isMapOff")
  showLogsChange() {
    if (!this.hovering) {
      this.localHoverForTimeout = true;
      setTimeout(() => {
        this.localHoverForTimeout = false;
      }, this.HOVER_TIMEOUT);
    }
  }

  @Watch("hovering", {})
  hoverChange() {
    this.localHoverForTimeout = this.hovering;
  }
  // ------- Lifecycle ---------
  created(): void {
    this.INITIAL_DIMEN.height = Math.floor(
      this.INITIAL_DIMEN.width / this.aspectRatio
    );
    window.addEventListener("resize", this.calcIdealSize);
    this.calcIdealSize();
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.calcIdealSize);
  }

  // --------- Methods ---------

  /** Gets the ideal size for the map based on the screen size. */
  calcIdealSize(): void {
    const width = window.innerWidth * 0.3;
    let heightScalar = 0.4;
    if (window.innerHeight < 600) {
      heightScalar = 0.2;
    }
    const height = window.innerHeight * heightScalar; // do this to account for bottom tags
    const idealWidth = Math.max(
      Math.floor(Math.min(width, height * this.aspectRatio)),
      120
    );

    const idealHeight = Math.floor(idealWidth / this.aspectRatio);
    this.idealSize = {
      width: idealWidth,
      height: idealHeight,
    };
  }

  /** Gets the zoom level in the enum */
  get zoomLevel(): number {
    switch (this.mapZoomLevel) {
      case MapZoomLevels.CLOSE: {
        return this.CLOSE_ZOOM_LEVEL;
      }
      case MapZoomLevels.FAR: {
        return this.FAR_ZOOM_LEVEL;
      }
      default: {
        return 0;
      }
    }
  }

  /** Gets the maps url for the image. */
  get googleMapsURL(): string {
    if (this.showMap) {
      const camLocation = (this.eventCamera as Camera).location;
      return `https://maps.googleapis.com/maps/api/staticmap?zoom=${this.zoomLevel}&size=${this.INITIAL_DIMEN.width}x${this.INITIAL_DIMEN.height}&markers=color:red%7C${camLocation.lat},${camLocation.lng}&key=${this.G_MAPS_KEY}`;
    } else {
      return "";
    }
  }

  /** Returns a link to the camera's location on google maps. */
  get linkToMaps(): string {
    if (this.showMap) {
      const camLocation = (this.eventCamera as Camera).location;
      return `https://google.com/maps/search/?api=1&query=${camLocation.lat},${camLocation.lng}`;
    } else {
      return "";
    }
  }

  /** Checks if the map is set into the off state */
  get isMapOff(): boolean {
    return this.mapZoomLevel === MapZoomLevels.OFF;
  }

  /** Returns true if the map should be shown. */
  get shouldShow(): boolean {
    return this.showMap && !this.isMapOff && this.localHoverForTimeout;
  }
}
