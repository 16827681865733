
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TheVideoPlayer from "@/components/TheVideoPlayer.vue";
import ThePanZoomOverlay from "@/components/ThePanZoomOverlay.vue";
import TheVideoInfo from "@/components/TheVideoInfo.vue";
import TheFrameByFrameAdvanceButtons from "@/components/TheFrameByFrameAdvanceButtons.vue";
import TheGoogleMapsOverlay from "@/components/TheGoogleMapsOverlay.vue";
import TheRealVideoDimensionsReferenceOverlay from "@/components/TheRealVideoDimensionsReferenceOverlay.vue";
import TheGoogleMapToggleButton from "@/components/TheGoogleMapToggleButton.vue";
import TheDownloadProgressBar from "@/components/TheDownloadProgressBar.vue";
import TheAccessLogger from "@/components/TheAccessLogger.vue";
import TheComplianceLogger from "@/components/TheComplianceLogger.vue";
import TheAccessControlToggleButton from "@/components/TheAccessControlToggleButton.vue";
import TheComplianceToggleButton from "@/components/TheComplianceToggleButton.vue";
import TheScreenshotButton from "@/components/TheScreenshotButton.vue";
import TheUrgentUploadOverlay from "@/components/TheUrgentUploadOverlay.vue";
import TheSendTailgatingEmailButton from "@/components/TheSendTailgatingEmailButton.vue";
import timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";

import {
  Bucket,
  Camera,
  CameraNameClickFactoryFunction,
  DownloadProgress,
  MapZoomLevels,
  PlayerTypes,
  QueueInteractionFunction,
  VideoInfo,
  VideoSource,
} from "@/types";
import { calculateSizeWithAspectRatio } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: {
    TheVideoPlayer,
    ThePanZoomOverlay,
    TheVideoInfo,
    TheFrameByFrameAdvanceButtons,
    TheGoogleMapsOverlay,
    TheGoogleMapToggleButton,
    TheDownloadProgressBar,
    TheAccessLogger,
    TheComplianceLogger,
    TheAccessControlToggleButton,
    TheComplianceToggleButton,
    TheScreenshotButton,
    TheRealVideoDimensionsReferenceOverlay,
    TheUrgentUploadOverlay,
    TheSendTailgatingEmailButton,
  },
})
export default class TheVideoPlayerContainer extends Vue {
  // ---------- Props ----------

  /** The type of the player being played (currently, PANZOOM or NONE) */
  @Prop() playerType!: PlayerTypes;

  /** The video player options for videojs. */
  @Prop({ default: {} }) videoOptions!: VideoJsPlayerOptions;

  /** The video info to display below a video. */
  @Prop() videoInfo!: VideoInfo | undefined;

  /** The framerate of the video. */
  @Prop() videoFramerate!: number;

  /** The aspect ratio of the video object. */
  @Prop() videoAspectRatio!: number;

  /** The aspect ratio of the video player. */
  @Prop() playerAspectRatio!: number;

  /** The camera and all it's metadata corresponding to this event. */
  @Prop() eventCamera!: Camera | null;

  /** The current progress of all of the video downloads */
  @Prop() downloadProgress!: DownloadProgress;

  /** The metadata associated with this event. */
  @Prop() videoSourcesWithMetaData!: VideoSource[];

  /** Whether or not the viewer is in restricted mode. */
  @Prop() isViewerRestricted!: boolean | undefined;

  /** The current query in the camio search bar. */
  @Prop() currentQuery!: string;

  /** A function for making urgent upload calls. */
  @Prop() queueInteraction!: QueueInteractionFunction;

  /** An outside function for performing a search with a selected label. */
  @Prop() cameraNameClickFactory!: CameraNameClickFactoryFunction;

  /** Tells us if the videos are still loading. */
  @Prop() videosLoaded!: boolean;

  // ------- Local Vars --------

  /** The container around the two swappable video elements. */
  pannableVideo: HTMLElement | null = null;

  /** The currently showing player. */
  activePlayer: VideoJsPlayer | null = null;

  /** The list of players that have been loaded (returned from the videojs players) */
  playerList: VideoJsPlayer[] = [];

  /** The dimensions of the video element. */
  playerDimensions = {
    width: 1280,
    height: 720,
  };

  /** The cursor to show when hovering over the video player. */
  cursor = "default";

  /** Whether or not the user is hovering over the video. */
  hoveringOverVideo = false;

  /** Keep track if the user has paused or if we need to try and play the video because it paused by itself. */
  userHasPaused = false;

  /** Figure out the zoom levels. */
  mapZoomLevel: MapZoomLevels = MapZoomLevels.OFF;

  /** Shows access control logs if applicable. @default true */
  showAccessControlLogs = true;

  /** Shows compliance logs if applicable. @default true */
  showComplianceLogs = true;

  /** Only show the access control button if we find we have events to work with. */
  showAccessControlButton = true;

  /** Only show the compliance button if we find we have events to work with. */
  showComplianceButton = true;

  /** Is set to true the first active player has loaded. */
  firstPlayerHasLoaded = false;

  /** The time in seconds (a string for reactivity) where we should seek to in the video. When this value changes, it triggers a seek on the video player. */
  videoSeekTime: { newTime?: number } = {};

  /** The literal name of the variable defined in this file that will be cookied / uncookied */
  valuesToCookie: (keyof this)[] = [
    "mapZoomLevel",
    "showAccessControlLogs",
    "showComplianceLogs",
  ];

  /** The prefix to prepend to cookies to avoid name conflicts. */
  COOKIE_PREFIX = "camio-viewer-vue-";

  // --------- Watchers --------

  @Watch("playerType")
  playerTypeChange(): void {
    this.updateCursor();
    this.hoverChange(true);
  }

  @Watch("watchForChangesInCookies")
  updateCookies() {
    this.updateOrCreateCookiedValues();
  }

  // ------- Lifecycle ---------

  created() {
    this.getCookiedValues();
    this.updateOrCreateCookiedValues();

    // subscribe to resize events
    window.addEventListener("resize", this.handleResize);
    this.playerDimensions = calculateSizeWithAspectRatio(
      this.playerAspectRatio
    );
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
  // --------- Methods ---------

  /** Trick to watch for changes in cookies and trigger update */
  get watchForChangesInCookies(): string {
    return this.valuesToCookie.reduce((prev, curr) => {
      return `${prev}${this[curr]}`;
    }, "");
  }

  /** Used to make sure that the camera has a location (which currently means location is defined and neither are 0) */
  get shouldShowMap(): boolean {
    if (this.eventCamera && this.eventCamera.location) {
      const lat = this.eventCamera.location.lat;
      const long = this.eventCamera.location.lng;

      if (lat && long && lat !== 0 && long !== 0) {
        return true;
      }
    }
    return false;
  }

  get currentBucket(): Bucket | undefined {
    if (this.videoSourcesWithMetaData.length == 0) {
      return undefined;
    } else {
      return this.videoSourcesWithMetaData[0].bucket;
    }
  }

  /** Turns the labels into a set in case the video sources have different ones for whatever reason */
  get labelsAsSet() {
    const labels = this.videoSourcesWithMetaData
      .map((videoSource) => {
        return videoSource.labels;
      })
      .flat();
    const set = new Set(labels);
    const backAsArray = Array.from(set);
    return backAsArray;
  }

  /** Function that is called when the user has clicked on a time and we should seek to the raw timestamp string equivilant in the video. */
  jumpToTimestamp(timestamp: string) {
    dayjs.extend(timezone);
    dayjs.extend(UTC);
    if (this.videoInfo) {
      const dateToJumpTo = dayjs(timestamp).tz(dayjs.tz.guess(), true);
      const startTime = this.videoInfo.startDate;
      const diffInSeconds = dateToJumpTo.diff(startTime, "s", true);

      // Make sure we are in bounds of the video!
      if (diffInSeconds > this.videoInfo.totalVideoLength) {
        this.videoSeekTime = { newTime: this.videoInfo.totalVideoLength - 1 };
        console.warn(
          "Requested to seek outside video! Potentially an encoding or rounding problem."
        );
      } else {
        this.videoSeekTime = { newTime: diffInSeconds };
      }
    }
  }
  /** Function that is called when the videojs players are created. */
  populatePlayers(players: {
    playerList: VideoJsPlayer[];
    activePlayer: number;
    inactivePlayer: number;
    pannablePlayer: Element;
  }): void {
    // Save the player list
    this.playerList = players.playerList;

    this.activePlayer = players.playerList[players.activePlayer];

    const videoElem = players.playerList[players.activePlayer].el()
      .children[0] as HTMLElement;

    if (videoElem) {
      this.pannableVideo = videoElem;
    } else {
      this.pannableVideo = players.playerList[
        players.activePlayer
      ].el() as HTMLElement;
    }

    this.activePlayer.on("loadedmetadata", () => {
      (this.activePlayer as VideoJsPlayer).off("loadedmetadata");
      this.firstPlayerHasLoaded = true;
    });
  }

  /** Function that handles the update of whether the user is hovering over the panzoom overlay. */
  hoverChange(isHovering: boolean): void {
    this.hoveringOverVideo = isHovering;
    this.updateCursor();
  }

  /** If panzoom is active, show a "move" cursor.  */
  updateCursor(): void {
    if (this.playerType === PlayerTypes.PANZOOM) {
      this.cursor = "move";
    } else {
      this.cursor = "default";
    }
  }

  /** Keeps track of the user's desired pause/play state. */
  userPauseEvent(isPausedNow: boolean): void {
    this.userHasPaused = isPausedNow;
  }

  /** Updates the map zoom level on a button toggle. */
  updateMapZoomLevel(): void {
    const length = Object.keys(MapZoomLevels).length;
    const currentIndex = Object.keys(MapZoomLevels).indexOf(this.mapZoomLevel);
    if (currentIndex + 1 === length) {
      this.mapZoomLevel = Object.keys(MapZoomLevels)[0] as MapZoomLevels;
    } else {
      this.mapZoomLevel = Object.keys(MapZoomLevels)[
        currentIndex + 1
      ] as MapZoomLevels;
    }

    if (this.showAccessControlLogs && this.mapZoomLevel !== MapZoomLevels.OFF) {
      this.showAccessControlLogs = false;
    }
  }

  /** Handle the resize event for calculating size of video */
  handleResize() {
    this.playerDimensions = calculateSizeWithAspectRatio(
      this.playerAspectRatio
    );
  }

  /** Toggles conflicting components off and turns on access control */
  toggleAccessControl(): void {
    if (this.showAccessControlLogs) {
      this.showAccessControlLogs = false;
    } else {
      if (this.mapZoomLevel !== MapZoomLevels.OFF) {
        this.mapZoomLevel = MapZoomLevels.OFF;
      }
      this.showAccessControlLogs = true;
    }
  }

  /** Toggles on and off the compliance (vector search) events */
  toggleCompliance(): void {
    if (this.showComplianceLogs) {
      this.showComplianceLogs = false;
    } else {
      this.showComplianceLogs = true;
    }
  }

  /** Cookies the current state of each variable we want to save. */
  updateOrCreateCookiedValues() {
    this.valuesToCookie.forEach((potentialCookie) => {
      localStorage.setItem(
        this.COOKIE_PREFIX + String(potentialCookie),
        String(this[potentialCookie])
      );
    });
  }

  /** Gets all the cookied values from localstorage on init and only updates them if they existed. */
  getCookiedValues() {
    this.valuesToCookie.forEach((potentialCookie) => {
      const cookiedItem = localStorage.getItem(
        this.COOKIE_PREFIX + String(potentialCookie)
      );
      if (cookiedItem) {
        if (typeof this[potentialCookie] === "boolean") {
          this[potentialCookie] = (cookiedItem === "true") as any;
        } else if (typeof this[potentialCookie] === "number") {
          this[potentialCookie] = Number(cookiedItem) as any;
        } else {
          this[potentialCookie] = cookiedItem as any;
        }
      }
    });
  }
}
