
import { VideoSource } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";
import { initPopover } from "@/plugins/bootstrapSetup";

export type CommonComplianceFields = {
  id: string; // A Unique identifier for this event
  cursor: string; // The cursor type to show when hovering over the event
  expandFunction: () => void; // The function to call when the event is clicked
  customClasses: string; // Any custom classes to add to the event
  resultsUrl?: string; // Link to the Vector Search results
  // Required fields, otherwise don't display event
  timestampLinkable?: string; // will have the raw timestamp OR undefined
  date?: string; // The date converted to a dayjs string as specified by the dateFormat below
  compliant: string; // "compliant" or "non-compliant"
  eventHumanReadable: string; // "compliant" or "noncompliant" display text
  overflowEventTitle?: string; // The title to show on the overflow title
  collapsed: boolean; // Whether or not to show the message or if it is a collapsed message that should be hidden
};
export interface ComplianceResultsInfo extends CommonComplianceFields {
  displayText: string;
  title: string;
}

@Component({})
export default class TheComplianceLogger extends Vue {
  // ---------- Props ----------

  /** The metadata associated with this event. */
  @Prop() videoSourcesWithMetaData!: VideoSource[];

  /** Whether or not the user is hovering over the viewer. */
  @Prop() hovering!: boolean;

  /** Whether or not to display the component. */
  @Prop() showComplianceLogger!: boolean;

  /** The current query in the camio search bar. */
  @Prop() currentQuery!: string;

  /** Tells us if the videos are still loading. */
  @Prop() videosLoaded!: boolean;

  /** The list of labels as a set */
  @Prop() labelsAsSet!: string[];

  // ------- Local Vars --------
  /** The format to put the date in (matches camio_viewer.js currently) */
  dateFormat = "h:mm:ssa"; // See: https://day.js.org/docs/en/display/format

  /** The regex that captures the compliant/non-compliant label */
  complianceRegex = /^(compliant|noncompliant)$/i;
  complianceLabelFound = false;
  compliant?: boolean = undefined;
  resultsLabelFound = false;
  resultsUrl?: string = undefined;
  /** The regex that captures Vector Search recommended messages */
  recommendedMessageRegex = /^_vs_rm:(.*)$/i;
  /** The regex that captures Vector Search compliance assessments */
  policyAssessmentRegex = /^_vs_pa:(compliant|noncompliant):(.*)$/i;
  /** The regex that captures Vector Search results url */
  resultsResultUrlRegex = /^_vs_result:(.*)$/i;

  /** Whether or not the control logs are showing. */
  isExpanded = false;

  /** Used so we can set a timeout on local hover changes so things aren't so abrupt. */
  localHoverForTimeout = false;

  /** Wait before changing the hover state to false */
  HOVER_TIMEOUT = 2000;

  /** Shows a checkmark on successful copy. */
  successfulCopy = false;
  successfulCopyIndex = -1;

  // --------- Watchers --------

  @Watch("showComplianceLogger")
  showLogsChange() {
    if (!this.hovering) {
      this.localHoverForTimeout = true;
      setTimeout(() => {
        this.localHoverForTimeout = false;
      }, this.HOVER_TIMEOUT);
    }
  }

  @Watch("hovering")
  hoverChange(newState: boolean) {
    this.localHoverForTimeout = newState;
  }

  // ------- Lifecycle ---------
  created() {
    // Called when a component is initialized
  }

  mounted() {
    // Called when the component is mounted on a page
    // all this does is forces the getter to run on init
    if (this.$refs.complianceLogger) {
      initPopover(this.$refs.complianceLogger as Element, undefined, false);
    }
    if (this.$refs.overallCompliance) {
      initPopover(this.$refs.overallCompliance as Element, undefined, false);
    }
  }

  // --------- Methods ---------

  /** Finds all labels that match the passed in regex expression */
  findLabelWithRegex(labels: string[], regex: RegExp) {
    const relevantLabels = labels
      .map((label) => {
        const matches = regex.exec(label);
        if (matches) {
          return matches;
        } else {
          return undefined;
        }
      })
      .filter((val): val is RegExpExecArray => !!val);
    return relevantLabels;
  }

  /** Converts a string to title/capital case when it is delimited by spaces. */
  convertToUpperCase(str: string | undefined): string | undefined {
    return str
      ? str
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : undefined;
  }

  /** Makes a dot or underscore seperated string into capitalized words for displaying. */
  makeWords(str: string): string {
    // Handle special case for emails
    const findEmailRegex = /.*@.+\..+/;
    const emailMatches = findEmailRegex.exec(str);
    if (emailMatches) {
      const email = emailMatches[0];
      return email;
    } else {
      return this.convertToUpperCase(
        str.replaceAll(".", " ").replaceAll("_", " ")
      ) as string;
    }
  }

  /** Expands the compliance logs to show all of them. */
  expandToggle(): void {
    this.isExpanded = true;
  }

  /** Shows compliance logs truncated to two. */
  contractToggle(): void {
    this.isExpanded = false;
  }

  /** Checks if we can link this time, and if we can, go to it by emitting an event. */
  goToTimestamp(timestamp: string | undefined): void {
    if (timestamp && this.videosLoaded) {
      this.$emit("jump-to-timestamp", timestamp);
    }
  }

  /** Creates a link based on the current query and the label */
  makeLabelLink(
    label: string | undefined,
    searchJustId: boolean,
    rawLabel?: string
  ): string {
    if (!label) {
      return "";
    }
    const urlBase = window.location.origin;
    let idToSearch = label.replaceAll("_", " ");
    let query = this.currentQuery;
    if (this.currentQuery) {
      // remove the labels we are about to add, note that we don't need to escape the "
      query = query.replaceAll(new RegExp(`"?${rawLabel}"?[ ]*`, "g"), " ");
      query = query.replaceAll(new RegExp(`"?${idToSearch}"?[ ]*`, "g"), " ");
    }

    let newQuery = "";

    if (searchJustId) {
      newQuery = `"${idToSearch}"`;
    } else {
      newQuery = `"${rawLabel}"${query ? " " + query : ""}`;
    }
    return `${urlBase}/app/#search;q=${encodeURIComponent(newQuery)}`;
  }

  /** V2 of makeLabelLink which takes in the formattedThingToSearch - which should be formatted in exactly the way you want it to be searched, and whether or not to append to current query and returns a full link. */
  makeLink(
    formattedThingToSearch: string | undefined,
    appendToQuery: boolean
  ): string | undefined {
    if (!formattedThingToSearch) {
      return undefined;
    }

    const urlBase = window.location.origin;
    let query = this.currentQuery;
    if (this.currentQuery) {
      // remove the labels we are about to add, note that we don't need to escape the "
      query = query.replaceAll(
        new RegExp(`"?${formattedThingToSearch}"?[ ]*`, "g"),
        " "
      );
    }

    let newQuery = "";

    if (appendToQuery) {
      newQuery = `"${formattedThingToSearch}"${query ? " " + query : ""}`;
    } else {
      newQuery = `"${formattedThingToSearch}"`;
    }
    return `${urlBase}/app/#search;q=${encodeURIComponent(newQuery)}`;
  }

  /** Tells us when to make something linkable or not and returns the string value if so. Otherwise returns an empty string (falsy). */
  getComplianceSearchType(complianceObject: ComplianceResultsInfo) {
    return "";
  }

  /** If there is more than 1, for ui reasons, we want to truncate the array of compliance events.
   * Note, this just sets collapsed to true if they should't show so we can still set the popovers.  */
  truncateComplianceArray(
    complianceToDisplay: (ComplianceResultsInfo | ComplianceResultsInfo)[]
  ) {
    if (complianceToDisplay.length > 1 && !this.isExpanded) {
      const extraEvents = complianceToDisplay.length - 1;
      // Iterate through the complianceToDisplay and set collapsed to true starting after index 1 (leave first two)
      const truncated = complianceToDisplay.map((compliance, index) => {
        if (index > 0) {
          compliance.collapsed = true;
        }
        return compliance;
      });
      // const remainingInfo = complianceToDisplay
      //   .slice(2)
      //   .reduce((prev, curr, index) => {
      //     prev.date = curr.date;
      //     prev.id = curr.id;
      //     return prev;
      //   }, {} as { [key: string]: string });

      const fillerEvent = {
        id: "filler-event",
        collapsed: false,
        overflowEventTitle: `+ ${extraEvents} more event${
          extraEvents !== 1 ? "s" : ""
        }`,
        event: "",
        cursor: "pointer",
        expandFunction: this.expandToggle,
        customClasses: "is-dark",
        displayText: "",
        title: "",
        compliant: "",
        eventHumanReadable: "",
      };
      truncated.push(fillerEvent);

      return truncated;
    } else {
      return complianceToDisplay;
    }
  }

  /** Returns the compliance object that contains the desired label */
  complianceObjectsWithLongLabel(
    overallCompliance: string,
    resultsUrl: string,
    allMatchingLabels: RegExpExecArray[],
    title: string
  ): ComplianceResultsInfo[] {
    // dayjs.extend(timezone);
    // dayjs.extend(UTC);

    const fullInfoComplianceObjects: ComplianceResultsInfo[] = allMatchingLabels
      .map((regexMatch, index) => {
        // This is in the format
        // _vs_rm:{{text}}
        // _vs_pa:{{compliant|noncomplaint}}:{{text}}
        const [compliant, message] = regexMatch.slice(1);
        // const rawTimestamp = timestamp.toUpperCase();
        // const date = dayjs(rawTimestamp)
        //   .tz(dayjs.tz.guess(), true)
        //   .format(this.dateFormat);

        if (message === "null" || !message) {
          return undefined;
        }

        return {
          id: `${index}-message`, // A Unique identifier for this event
          collapsed: false, // Whether or not to show the event or if it is a collapsed event that should be hidden
          cursor: this.isExpanded ? "pointer" : "default", // The cursor type to show when hovering over the event
          expandFunction: this.contractToggle, // The function to call when the event is clicked
          customClasses: "", // Any custom classes to add to the event
          // Required fields, otherwise don't display event
          eventHumanReadable: this.makeWords(compliant), // The event type converted to a human readable string (Compliant or Noncompliant)
          compliant:
            compliant != undefined
              ? compliant.toUpperCase()
              : overallCompliance,
          resultsUrl: resultsUrl,
          displayText: message,
          title: title,
        } as ComplianceResultsInfo;
      })
      .filter(
        (complianceObj): complianceObj is ComplianceResultsInfo =>
          !!complianceObj
      );

    const sortedCompliance = fullInfoComplianceObjects;

    // If there were no access control events found, then let the parent know not to show the button
    if (sortedCompliance.length === 0 && !this.complianceLabelFound) {
      this.$emit("hide-access-control-button");
    }

    return this.truncateComplianceArray(
      sortedCompliance
    ) as ComplianceResultsInfo[];
  }

  /** Get full info compliance objects */
  get fullInfoComplianceObjects() {
    const policyAssessmentLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.policyAssessmentRegex
    );
    const recommendedMessageLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.recommendedMessageRegex
    );
    const allMatchingComplianceLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.complianceRegex
    );
    const allMatchingResultsLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.resultsResultUrlRegex
    );
    const containsNoncompliant = (arr: any[]) =>
      arr.some((match: string[]) => match[0].toLowerCase() === "noncompliant");
    // Handles displaying the ! Noncompliant vs Compliant header, only if a matching label is found
    // Message labels will display regardless
    if (allMatchingComplianceLabels.length > 0) {
      this.complianceLabelFound = true;
      this.compliant = !containsNoncompliant(allMatchingComplianceLabels);
    }
    if (allMatchingResultsLabels.length > 0) {
      this.resultsLabelFound = true;
      this.resultsUrl = allMatchingResultsLabels[0][1]; // NOTE: Currently only supports one results url label, with the assumption that all of the compliance results for the event are available at that url
    }
    const resultsUrl = this.resultsUrl ? this.resultsUrl : "";
    const complianceText = this.compliant ? "compliant" : "noncompliant"; // If no compliance label this will default to non-compliant
    const policyAssessmentComplianceObjects =
      this.complianceObjectsWithLongLabel(
        complianceText,
        resultsUrl,
        policyAssessmentLabels,
        "Policy Assessment"
      );

    return policyAssessmentComplianceObjects;
    // The below code can be used to display both the policy assessment and the recommended messages
    // const recommendedMessageComplianceObjects =
    //   this.complianceObjectsWithLongLabel(
    //     complianceText,
    //     resultsUrl,
    //     recommendedMessageLabels,
    //     "Recommended Message"
    //   );
    // const allComplianceObjects = policyAssessmentComplianceObjects.concat(
    //   recommendedMessageComplianceObjects
    // );
    // return allComplianceObjects;
  }

  /** Copies the passed in text to the clipboard */
  copyToClipboard(text: string, index: number) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.successfulCopy = true;
          this.successfulCopyIndex = index;
          setTimeout(() => {
            this.successfulCopy = false;
            this.successfulCopyIndex = -1;
          }, 2000);
        })
        .catch((err) => {
          console.error("Error copying text to clipboard: ", err);
        });
    } else {
      console.log("clipboard copy not supported by browser");
    }
  }
}
