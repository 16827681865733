// Note: these MUST be imported this way (not like `import { Popover } from "bootstrap"`) because importing it will run the entire esm module on the page which will include other js modules you may not want to leak into the parent (like modals for example)
import Popover from "bootstrap/js/dist/popover";
import Dropdown from "bootstrap/js/dist/dropdown";

export const initPopover = (
  parentElemToInitWith: Element,
  content?: string,
  sanitize?: boolean
): Popover[] => {
  // Initialize tooltips
  const popoverList = parentElemToInitWith.querySelectorAll(
    '[data-bs-toggle="popover"]'
  );

  const sanitized = sanitize == false ? false : true;

  const popoverRefs: Popover[] = [];
  if (popoverList) {
    popoverList.forEach((popoverTriggerEl) => {
      let popover;
      if (content) {
        popover = new Popover(popoverTriggerEl, {
          content,
          sanitize: sanitized,
        });
      } else {
        popover = new Popover(popoverTriggerEl, { sanitize: sanitized });
      }

      popoverRefs.push(popover);
      return popover;
    });
  } else {
    console.warn("No Popovers Found!");
  }

  return popoverRefs;
};

export const initDropdown = (
  parentElemToInitWith: Element,
  content?: string
): Dropdown[] => {
  // Initialize dropdowns
  const dropdownList = parentElemToInitWith.querySelectorAll(
    '[data-bs-toggle="dropdown"]'
  );

  const dropdownRefs: Dropdown[] = [];
  if (dropdownList) {
    dropdownList.forEach((dropdownToggleEl) => {
      let dropdown;
      if (content) {
        dropdown = new Dropdown(dropdownToggleEl);
      } else {
        dropdown = new Dropdown(dropdownToggleEl);
      }

      dropdownRefs.push(dropdown);
      return dropdown;
    });
  } else {
    console.warn("No Dropdowns Found!");
  }

  return dropdownRefs;
};
