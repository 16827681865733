/** Extracts a screenshot from the current video element url. */
export const extractScreenshot = (
  videoElement: HTMLVideoElement,
  format: "jpeg" | "png" | "buffer"
): string | Uint8Array | void => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (videoElement && ctx) {
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    switch (format) {
      case "png":
        return canvas.toDataURL("image/png");
      case "jpeg":
        return canvas.toDataURL("image/jpeg");
      case "buffer":
        return new Uint8Array(
          ctx.getImageData(
            0,
            0,
            videoElement.videoWidth,
            videoElement.videoHeight
          ).data.buffer
        );
    }
  } else {
    console.log("No video element or context", videoElement, ctx);
  }
};

/** Downloads an item from the source.  */
export const downloadFromSrc = (
  type: "jpeg" | "png" | "mp4",
  fileName: string,
  image: string
) => {
  const link = document.createElement("a");
  link.href = image;
  link.download = fileName + `.${type}`;
  link.click();
};

/** Formats incoming file name to a file friendly format. */
export const makeSafeFileName = (fileName: string) => {
  return fileName.replace(/[^a-z0-9-]/gi, "_").toLowerCase();
};

/** Calculates the size with the passed in aspect ratio */
export const calculateSizeWithAspectRatio = (
  aspectRatio: number
): {
  width: number;
  height: number;
} => {
  // Always maximize real estate of the viewer
  const width = document.documentElement.clientWidth;
  let offsetForInfo = 180;
  if (document.documentElement.clientHeight < 550) {
    offsetForInfo = 160;
  }

  if (document.documentElement.clientHeight < 400) {
    offsetForInfo = 140;
  }

  const height = document.documentElement.clientHeight - offsetForInfo; // do this to account for bottom tags
  const idealWidth = Math.max(
    Math.floor(Math.min(width, height * aspectRatio)),
    300
  );
  const idealHeight = Math.floor(idealWidth / aspectRatio);
  return {
    width: idealWidth,
    height: idealHeight,
  };
};
