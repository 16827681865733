
import { VideoSource } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import UTC from "dayjs/plugin/utc";
import { initPopover } from "@/plugins/bootstrapSetup";

export type CommonAccessControlFields = {
  id: string; // A Unique identifier for this event
  cursor: string; // The cursor type to show when hovering over the event
  expandFunction: () => void; // The function to call when the event is clicked
  customClasses: string; // Any custom classes to add to the event
  // Required fields, otherwise don't display event
  integrationName: string; // The name of the integration (pacs, openpath, onguard, etc)
  timestampLinkable?: string; // will have the raw timestamp OR undefined
  date: string; // The date converted to a dayjs string as specified by the dateFormat below
  event: string; // The event type (entry.unlocked, entry.forcedopen, entry.ajar.started)
  overflowEventTitle?: string; // The title to show on the overflow title
  collapsed: boolean; // Whether or not to show the event or if it is a collapsed event that should be hidden
};
export interface AccessControlInfo extends CommonAccessControlFields {
  userName: string; // The name of the user (if available, be careful to replace strings defined as "null" with "Unkonwn")
  rawUserLabel?: string; // The raw user label (if available)
  badgeId?: string;
  searchableName?: string;
}

export interface AccessControlFullInfo extends CommonAccessControlFields {
  iterable: {
    // For display purposes
    humanReadable: string; // The human readable version of the users name/email/badgeid ("Mason Pierce")
    searchable: string; // Deprecated
    readableLink: string; // The link to the quoted version of the users name/email/badgeid ("mason pierce")
    readableText: string; // The quoted version of the users name/email/badgeid ("Mason Pierce")
    searchableLink: string; // The actual link to do the search on the event
    searchableText: string; // The text with the event appended on to show in the popover (mason_pierce_entry.unlocked)
    appendedLink: string; // The actual link to do the search on the plus button
    appendedText: string; // The text shown on the popover for the plus button
    icon: string; // The custom icon to show not used currently
  }[];
  eventHumanReadable: string; // The event type (Entry Unlocked, Entry Forced Open, Entry Ajar Started)
  // Optional fields
  actorIdHumanReadable?: string; // The id of the user (if available, be careful to replace strings defined as "0" with undefined to not show)
  actorIdSearchable?: string; // The id of the user combined with the access control event (1234_entry.unlocked)
  userNameHumanReadable?: string; // The name of the user (if available, be careful to replace strings defined as "null" with "Unknown")
  userNameSearchable?: string; // The name of the user combined with the access control event (mason_pierce_entry.unlocked)
  userEmailHumanReadable?: string; // The email of the user (if available)
  userEmailSearchable?: string; // The email of the user combined with the access control event (mason@camio.com_entry.unlocked)
}

@Component({})
export default class TheAccessLogger extends Vue {
  // ---------- Props ----------

  /** The metadata associated with this event. */
  @Prop() videoSourcesWithMetaData!: VideoSource[];

  /** Whether or not the user is hovering over the viewer. */
  @Prop() hovering!: boolean;

  /** Whether or not to display the component. */
  @Prop() showAccessLogger!: boolean;

  /** The current query in the camio search bar. */
  @Prop() currentQuery!: string;

  /** Tells us if the videos are still loading. */
  @Prop() videosLoaded!: boolean;

  /** The list of labels as a set */
  @Prop() labelsAsSet!: string[];

  // ------- Local Vars --------
  /** The format to put the date in (matches camio_viewer.js currently) */
  dateFormat = "h:mm:ssa"; // See: https://day.js.org/docs/en/display/format

  /** The regex that captures the name and event type from access events. Note: For forced open events, there are no users... so we also try without the prefix */
  accessControlEventRegex =
    /^(.*)(entry.unlocked|entry.forcedopen|entry.ajar.started)$/i;

  /** The intermediate regex until we add a json descriptor into the event (later). Regex that will match the following (case insensitive): integration:{{integration}}:{{timestamp}}:{{event_type}}:{{actor_id}}:{{actor_email}}:{{actor_name}} */
  fullInfoIntegrationRegex =
    /^integration:([^:]+):(.*[^:][^a-z]+):([^:]+):([^:]*):([^:]*):([^:]*)$/i;

  /** Grabs the component from the integration */
  integrationRegex = /integration:(.*)/i;

  /** The regex used for mapping, integration<->event<->date */
  dynamicIntegrationRegexString =
    "([integration]):(entry.unlocked|entry.forcedopen|entry.ajar.started):(.*)";

  /** Custom user event regex */
  customUserEventRegex = /^_pos_([^:]+):(.+)/i;

  /** Regex for labels that tell us how many people have walked in and how many people have badged in. */
  transitionCountRegex = /^_tg_human_transition:([0-9]+)$/i;
  humanUnlockRegex = /^_tg_human_unlock:([0-9]+)$/i;

  /** Used for making custom events since we still technically need an access control name like "openpath" in the ui. */
  customUserEventIntegrationName = "Camio";

  /** The name of the "user" for a custom event. This design probably needs to be reworked in the future to better convey what is happening. */
  customEventName = "Custom Camio Event";

  /** Whether or not the control logs are showing. */
  isExpanded = false;

  /** Used so we can set a timeout on local hover changes so things aren't so abrupt. */
  localHoverForTimeout = false;

  /** Wait before changing the hover state to false */
  HOVER_TIMEOUT = 2000;

  /** Shows a checkmark on successful copy. */
  successfulCopy = false;
  successfulCopyIndex = -1;

  /** Legacy access control object */
  legacyAccessControlObjects: AccessControlInfo[] = [];

  // --------- Watchers --------

  @Watch("showAccessLogger")
  showLogsChange() {
    if (!this.hovering) {
      this.localHoverForTimeout = true;
      setTimeout(() => {
        this.localHoverForTimeout = false;
      }, this.HOVER_TIMEOUT);
    }
  }

  @Watch("hovering")
  hoverChange(newState: boolean) {
    this.localHoverForTimeout = newState;
  }

  // ------- Lifecycle ---------
  created() {
    // Called when a component is initialized
  }

  mounted() {
    // Called when the component is mounted on a page
    // all this does is forces the getter to run on init
    this.legacyAccessControlObjects = this.accessControlObjects();
    if (this.$refs.accessLogger) {
      initPopover(this.$refs.accessLogger as Element, undefined, false);
    }
  }

  // --------- Methods ---------

  /** Finds all labels that match the passed in regex expression */
  findLabelWithRegex(labels: string[], regex: RegExp) {
    const relevantLabels = labels
      .map((label) => {
        const matches = regex.exec(label);
        if (matches) {
          return matches;
        } else {
          return undefined;
        }
      })
      .filter((val): val is RegExpExecArray => !!val);
    return relevantLabels;
  }

  /** Converts a string to title/capital case when it is delimited by spaces. */
  convertToUpperCase(str: string | undefined): string | undefined {
    return str
      ? str
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : undefined;
  }

  /** Makes a dot or underscore seperated string into capitalized words for displaying. */
  makeWords(str: string): string {
    // Handle special case for emails
    const findEmailRegex = /.*@.+\..+/;
    const emailMatches = findEmailRegex.exec(str);
    if (emailMatches) {
      const email = emailMatches[0];
      return email;
    } else {
      return this.convertToUpperCase(
        str.replaceAll(".", " ").replaceAll("_", " ")
      ) as string;
    }
  }

  /** Expands the access logs to show all of them. */
  expandToggle(): void {
    this.isExpanded = true;
    this.legacyAccessControlObjects = this.accessControlObjects();
  }

  /** Shows access logs truncated to two. */
  contractToggle(): void {
    this.isExpanded = false;
    this.legacyAccessControlObjects = this.accessControlObjects();
  }

  /** Checks if we can link this time, and if we can, go to it by emitting an event. */
  goToTimestamp(timestamp: string | undefined): void {
    if (timestamp && this.videosLoaded) {
      this.$emit("jump-to-timestamp", timestamp);
    }
  }

  /** Creates a link based on the current query and the label */
  makeLabelLink(
    label: string | undefined,
    searchJustId: boolean,
    rawLabel?: string
  ): string {
    if (!label) {
      return "";
    }
    const urlBase = window.location.origin;
    let idToSearch = label.replaceAll("_", " ");
    let query = this.currentQuery;
    if (this.currentQuery) {
      // remove the labels we are about to add, note that we don't need to escape the "
      query = query.replaceAll(new RegExp(`"?${rawLabel}"?[ ]*`, "g"), " ");
      query = query.replaceAll(new RegExp(`"?${idToSearch}"?[ ]*`, "g"), " ");
    }

    let newQuery = "";

    if (searchJustId) {
      newQuery = `"${idToSearch}"`;
    } else {
      newQuery = `"${rawLabel}"${query ? " " + query : ""}`;
    }
    return `${urlBase}/app/#search;q=${encodeURIComponent(newQuery)}`;
  }

  /** V2 of makeLabelLink which takes in the formattedThingToSearch - which should be formatted in exactly the way you want it to be searched, and whether or not to append to current query and returns a full link. */
  makeLink(
    formattedThingToSearch: string | undefined,
    appendToQuery: boolean
  ): string | undefined {
    if (!formattedThingToSearch) {
      return undefined;
    }

    const urlBase = window.location.origin;
    let query = this.currentQuery;
    if (this.currentQuery) {
      // remove the labels we are about to add, note that we don't need to escape the "
      query = query.replaceAll(
        new RegExp(`"?${formattedThingToSearch}"?[ ]*`, "g"),
        " "
      );
    }

    let newQuery = "";

    if (appendToQuery) {
      newQuery = `"${formattedThingToSearch}"${query ? " " + query : ""}`;
    } else {
      newQuery = `"${formattedThingToSearch}"`;
    }
    return `${urlBase}/app/#search;q=${encodeURIComponent(newQuery)}`;
  }

  /** Finds non-user events... currently only events that start with _pos_ prefix. Needs to be refactored if want to include more. */
  findNonUserEvents(): AccessControlInfo[] {
    dayjs.extend(timezone);
    dayjs.extend(UTC);
    // find relevant labels based on regex
    const relevantLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.customUserEventRegex
    );

    let nonUserAccessEvents: AccessControlInfo[] = [];
    // We have the event name as the first value and the time as the second value
    // for each of the relevant labels
    relevantLabels.forEach((label, index) => {
      // The t in the label is lowercase, which breaks
      // on safari and firefox, so force to upper case
      const dateFromLabel = label[2].toUpperCase();
      const date = dayjs(dateFromLabel)
        .tz(dayjs.tz.guess(), true)
        .format(this.dateFormat);

      const eventName = label[1];
      nonUserAccessEvents.push({
        id: `non-user-${index}`,
        collapsed: false,
        date,
        userName: this.makeWords(eventName),
        integrationName: this.makeWords(this.customUserEventIntegrationName),
        event: "", // because we want it to look like Cleaning Start\nat 7:32:41am
        cursor: this.isExpanded ? "pointer" : "default",
        expandFunction: this.contractToggle,
        customClasses: "",
        // Need to refactor function that makes this searchable if we want to make the event clickable
        // rawNameLabel: label.input,
        timestampLinkable: dateFromLabel,
      });
    });
    return nonUserAccessEvents;
  }

  /** Tells us when to make something linkable or not and returns the string value if so. Otherwise returns an empty string (falsy). */
  getAccessControlSearchType(accessControlObject: AccessControlInfo) {
    if (accessControlObject.badgeId) {
      return accessControlObject.badgeId;
    } else if (accessControlObject.searchableName) {
      return accessControlObject.searchableName;
    } else {
      return "";
    }
  }

  /** Sorts the access control events by timestamp. */
  sortAccessControlByTimestamp(
    accessControlToSort: (AccessControlInfo | AccessControlFullInfo)[]
  ) {
    return accessControlToSort.sort((a, b) => {
      if (a.timestampLinkable && b.timestampLinkable) {
        return dayjs(a.timestampLinkable).isBefore(b.timestampLinkable)
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
  }

  /** If there is more than 2, for ui reasons, we want to truncate the array of access control events.
   * Note, this just sets collapsed to true if they should't show so we can still set the popovers.  */
  truncateAccessControlArray(
    accessControlToDisplay: (AccessControlInfo | AccessControlFullInfo)[]
  ) {
    if (accessControlToDisplay.length > 2 && !this.isExpanded) {
      const extraEvents = accessControlToDisplay.length - 2;
      // Iterate through the accessControlToDisplay and set collapsed to true starting after index 1 (leave first two)
      const truncated = accessControlToDisplay.map((accessControl, index) => {
        if (index > 1) {
          accessControl.collapsed = true;
        }
        return accessControl;
      });
      const remainingInfo = accessControlToDisplay
        .slice(2)
        .reduce((prev, curr, index) => {
          // We only want to list each kind of integration
          // if it is the same kind, we shouldn't show it twice
          if (index === 0) {
            prev.integrationNames = `${curr.integrationName}`;
          } else if (!prev.integrationNames.includes(curr.integrationName)) {
            prev.integrationNames = `${prev.integrationNames}, ${curr.integrationName}`;
          }
          prev.date = curr.date;
          prev.id = curr.id;
          return prev;
        }, {} as { [key: string]: string });

      const fillerEvent = {
        id: "filler-event",
        date: remainingInfo.date,
        collapsed: false,
        overflowEventTitle: `+ ${extraEvents} more event${
          extraEvents !== 1 ? "s" : ""
        }`,
        userName: `+ ${extraEvents} more event${extraEvents !== 1 ? "s" : ""}`,
        integrationName: remainingInfo.integrationNames,
        event: "",
        cursor: "pointer",
        expandFunction: this.expandToggle,
        customClasses: "is-dark",
      };
      truncated.push(fillerEvent);

      return truncated;
    } else {
      return accessControlToDisplay;
    }
  }

  buildSearchableLabel(event: string, actorInfo: string) {
    return `${actorInfo}_${event}`;
  }

  /** Returns the access control object that contains the desired label */
  /**
   * Some notes on design decisions here:
   * 1. We assume there exists the following labels: integration:<integration-name>, <integration-name>:<event-name>, <user-name>_<event-name>
   * 2. We can assume a 1-1 mapping because it is highly unlikely that a camera would be facing two doors with two seperate
   *    access control systems. If that is ever the case, this will just incorrectly say that the same person activated both events
   *    (even though that likely isn't true)
   * 3. If there is no mapping, this will just not show the access logs at all.
   * NOTE ON REFACTOR OF ABOVE:
   * * We now check for a label in the form: `integration:{{integration}}:{{timestamp}}:{{event_type}}:{{actor_id}}:{{actor_email}}:{{actor_name}}`
   * * This is temporary until we add a JSON blob into the event object and should help with migration
   */
  accessControlObjectsWithLongLabel(
    allMatchingLabels: RegExpExecArray[]
  ): AccessControlFullInfo[] {
    dayjs.extend(timezone);
    dayjs.extend(UTC);

    const fullInfoAccessControlObjects: AccessControlFullInfo[] =
      allMatchingLabels
        .map((regexMatch, index) => {
          // This is always in the format
          // 0: "integration:pacs:2023-06-01T19:28:31.618650+00:00:entry.unlocked:actorA:someemail@gmail.com:bob_smith"
          //1: "pacs"
          //2: "2023-06-01T19:28:31.618650+00:00"
          //3: "entry.unlocked"
          //4: "actorA"
          //5: "someemail@gmail.com"
          //6: "bob_smith"
          const nameChecks = ["null", "", "0"];
          const [
            integrationName,
            timestamp,
            event,
            actorId,
            actorEmail,
            actorName,
          ] = regexMatch.slice(1);
          const rawTimestamp = timestamp.toUpperCase();
          const date = dayjs(rawTimestamp)
            .tz(dayjs.tz.guess(), true)
            .format(this.dateFormat);

          let actorIdHumanReadable: string | undefined = actorId;
          if (actorIdHumanReadable === "0" || actorIdHumanReadable === "null") {
            actorIdHumanReadable = undefined;
          }
          let userNameHumanReadable: string | undefined = actorName;
          if (nameChecks.includes(actorName)) {
            userNameHumanReadable = undefined;
          }

          let userEmailHumanReadable: string | undefined = actorEmail;
          if (nameChecks.includes(actorEmail)) {
            userEmailHumanReadable = undefined;
          }

          actorIdHumanReadable = actorIdHumanReadable
            ? this.makeWords(actorIdHumanReadable)
            : undefined;
          let actorIdSearchable = actorIdHumanReadable
            ? this.buildSearchableLabel(event, actorIdHumanReadable)
            : undefined;
          userNameHumanReadable = userNameHumanReadable
            ? this.makeWords(userNameHumanReadable)
            : undefined;
          let userNameSearchable = userNameHumanReadable
            ? this.buildSearchableLabel(event, userNameHumanReadable)
            : undefined;
          userEmailHumanReadable = userEmailHumanReadable
            ? this.makeWords(userEmailHumanReadable)
            : undefined;
          let userEmailSearchable = userEmailHumanReadable
            ? this.buildSearchableLabel(event, userEmailHumanReadable)
            : undefined;

          let iterable = [
            // For actorId specifically, we want to show Unknown if the actorId is undefined
            {
              humanReadable: actorIdHumanReadable
                ? actorIdHumanReadable
                : "Unknown",
              searchable: actorIdSearchable,
              readableLink: this.makeLink(
                actorIdHumanReadable?.toLowerCase(),
                false
              ),
              readableText: actorIdHumanReadable?.toLowerCase() || "",
              searchableLink: this.makeLink(
                actorIdSearchable?.toLowerCase(),
                false
              ),
              searchableText: actorIdHumanReadable?.toLowerCase() || "",
              appendedLink: this.makeLink(
                actorIdHumanReadable?.toLowerCase(),
                true
              ),
              appendedText: actorIdHumanReadable?.toLowerCase() || "",
              icon: "person-badge",
            },
            {
              humanReadable: userNameHumanReadable,
              searchable: userNameSearchable,
              readableLink: this.makeLink(
                userNameHumanReadable?.toLowerCase(),
                false
              ),
              readableText: userNameHumanReadable?.toLowerCase() || "",
              searchableLink: this.makeLink(
                userNameSearchable?.toLowerCase()?.replace(" ", "_"),
                false
              ),
              searchableText: userNameHumanReadable?.toLowerCase() || "",
              appendedLink: this.makeLink(
                userNameHumanReadable?.toLowerCase(),
                true
              ),
              appendedText: userNameHumanReadable?.toLowerCase() || "",
              icon: "person",
            },
            {
              humanReadable: userEmailHumanReadable,
              searchable: userEmailSearchable,
              readableLink: false,
              // this.makeLink(
              //   userEmailHumanReadable?.toLowerCase(),
              //   false
              // ),
              readableText: "",
              searchableLink: "#", //this.makeLink(userEmailSearchable?.toLowerCase(),false),
              searchableText: "",
              appendedLink: false,
              appendedText: "",
              icon: "envelope",
            },
          ];

          // We don't add the event if the event is null, there is no timestamp, or there is no integration name
          /**
           * Explicitly, we don't show an event if the string is in the following formats
           * integration:brivo:2023-05-12t00:09:40.000-0000:null:null:null:null <- No event
           * integration:brivo:null:null:null:null:null <- No timestamp
           * integration:null:2023-05-12t00:09:40.000-0000:null:null:null:null <- No integration name
           */
          if (
            event === "null" ||
            integrationName === "null" ||
            timestamp === "null"
          ) {
            return undefined;
          }

          return {
            id: `${index}-long-label`, // A Unique identifier for this event
            collapsed: false, // Whether or not to show the event or if it is a collapsed event that should be hidden
            cursor: this.isExpanded ? "pointer" : "default", // The cursor type to show when hovering over the event
            expandFunction: this.contractToggle, // The function to call when the event is clicked
            customClasses: "", // Any custom classes to add to the event
            // Required fields, otherwise don't display event
            integrationName: this.makeWords(integrationName), // The name of the integration (pacs, openpath, onguard, etc)
            timestampLinkable: rawTimestamp, // will have the raw timestamp OR undefined
            date, // The date converted to a dayjs string as specified by the dateFormat below
            event, // The event type (entry.unlocked, entry.forcedopen, entry.ajar.started)
            eventHumanReadable: this.makeWords(event), // The event type converted to a human readable string (Entry Unlocked, Entry Forced Open, Entry Ajar Started)
            iterable,
            // Optional fields
            actorIdHumanReadable, // The id of the user (if available, be careful to replace strings defined as "0" with undefined to not show)
            actorIdSearchable, // The id of the user combined with the access control event (1234_entry.unlocked)
            userNameHumanReadable, // The name of the user (if available, be careful to replace strings defined as "null" with "Unknown")
            userNameSearchable, // The name of the user combined with the access control event (mason_pierce_entry.unlocked)
            userEmailHumanReadable, // The email of the user (if available)
            userEmailSearchable, // The email of the user combined with the access control event (mason@camio.com_entry.unlocked)
          } as AccessControlFullInfo;
        })
        .filter((accessObj): accessObj is AccessControlFullInfo => !!accessObj);

    const sortedAccessControl = this.sortAccessControlByTimestamp(
      fullInfoAccessControlObjects
    );

    return this.truncateAccessControlArray(
      sortedAccessControl
    ) as AccessControlFullInfo[];
  }
  accessControlObjectsLegacy(labelsAsSet: string[]): AccessControlInfo[] {
    dayjs.extend(timezone);
    dayjs.extend(UTC);

    // We don't want to iterate through video sources because then we get duplicate access events in the viewer.
    const videoSource = this.videoSourcesWithMetaData[0];
    videoSource.labels = labelsAsSet;
    const videoSourceArr = [videoSource];

    let accessControlToDisplay = videoSourceArr
      .flatMap((videoSource) => {
        // go through the labels and look for a name and its associated event
        const userAndEvent = this.findLabelWithRegex(
          videoSource.labels,
          this.accessControlEventRegex
        ).reduce((prev, curr, index) => {
          let name = curr[1];
          let event = curr[2];
          const nameChecks = ["null", "", "0"];
          // The regex will capture the value with the `_` at the end of it, so remove only the
          // ending _ if it exists (names are seperated by _'s so we can't just remove all _'s)
          if (typeof name === "string") {
            name = name.replace(/_$/, "");
          }
          // If entry unlocked event without name, we should skip it
          if (
            event === "entry.unlocked" &&
            (!name || nameChecks.includes(name))
          ) {
            return prev;
          }

          // For DFO and others where we truly don't have a name, set it to unknown
          if (nameChecks.includes(name) || !name) {
            name = "Unknown";
          }
          let nameType = "name" as "name" | "email" | "id";
          if (name.includes("@")) {
            nameType = "email";
          } else if (!isNaN(Number(name))) {
            nameType = "id";
          } else {
            nameType = "name";
          }

          prev[`${name}-${index}`] = {
            event,
            rawLabel: curr[0],
            userName: name,
            type: nameType,
          };

          return prev;
        }, {} as { [key: string]: { event: string; rawLabel: string; userName: string; type: "email" | "id" | "name" } });

        // Go through the labels and look for any integration names
        let integrationNames = this.findLabelWithRegex(
          videoSource.labels,
          this.integrationRegex
        ).map((integrations) => {
          const integrationName = integrations[1];
          return integrationName;
        });

        // for each integration name, go through the labels and check for a integration:event mapping
        let copyOfLabels = [...videoSource.labels];
        const integrationAndEvent = integrationNames
          .map((integrationName) => {
            const expr = this.dynamicIntegrationRegexString.replace(
              "[integration]",
              integrationName
            );
            const dynamicRegex = new RegExp(expr, "i");
            return this.findLabelWithRegex(copyOfLabels, dynamicRegex).reduce(
              (prev, curr) => {
                const integrationName = curr[1];
                const eventName = curr[2];
                let date = videoSource.date_created;
                let rawDate;
                if (curr.length >= 4) {
                  // The t in the label is lowercase, which breaks
                  // on safari and firefox, so force to upper case
                  rawDate = curr[3].toUpperCase();
                  date = dayjs(rawDate)
                    .tz(dayjs.tz.guess(), true)
                    .format(this.dateFormat);

                  // We already used the time for this, so get rid of it
                  copyOfLabels.splice(copyOfLabels.indexOf(curr[0]), 1);
                }

                // Push array of event:date pairings to use later
                if (!prev[integrationName]) {
                  prev[integrationName] = [{ eventName, date, rawDate }];
                } else {
                  prev[integrationName].push({ eventName, date, rawDate });
                }
                return prev;
              },
              {} as {
                [key: string]: {
                  eventName: string;
                  date: string;
                  rawDate?: string;
                }[];
              }
            );
          })
          .reduce((prev, curr) => {
            return Object.assign(prev, curr);
          }, {});

        // Used to keep track of us only setting an event once if the user is Unknown (avoiding duplicates)
        const unknownEvents: { [event: string]: boolean } = {};
        // Don't repeat usernames
        const usedTypesPerTimestamp = {} as {
          [timestamp: string]: Set<"name" | "email" | "id">;
        };
        const usedNames = new Set<string>();
        // Go through each of the integration and event mappings and connect them into one object
        const accessControlObjects: (AccessControlInfo | undefined)[][] =
          Object.keys(userAndEvent).map((userName, uindex) => {
            // for every user found, find every event they were a part of
            return Object.keys(integrationAndEvent)
              .map((integrationName, iindex) => {
                // then, for each of the times recorded, try to assign a user
                // Note that in theory, the times would line up, but I don't
                // have access to anything to map user<->userId<->time so
                // unfortunately this is the best we get.
                // go through each of the events per integration and try to find matches
                return integrationAndEvent[integrationName].map(
                  (eventWithDate, eindex) => {
                    // if the events match, save this combo
                    if (
                      userAndEvent[userName].event === eventWithDate.eventName
                    ) {
                      // Check if the user unknown has already been used for this event, and skip it if it has
                      if (
                        userName.includes("Unknown") &&
                        unknownEvents[eventWithDate.eventName]
                      ) {
                        return undefined;
                      } else if (userName.includes("Unknown")) {
                        unknownEvents[eventWithDate.eventName] = true;
                      }
                      let badgeId = undefined;
                      let searchableName = undefined;
                      // if and only if a number ID, then go ahead and create a link
                      if (/^\d+$/.test(userAndEvent[userName].userName)) {
                        // This label is likely something like 1234_entry.unlocked
                        badgeId = userAndEvent[userName].rawLabel.split("_")[0];

                        // Make it so you can search by name and email if not a number
                      } else if (
                        userAndEvent[userName].userName !== "Unknown"
                      ) {
                        searchableName = userAndEvent[userName].userName;
                      }

                      // If this timestamp hasn't been used, set it to an empty set
                      if (!usedTypesPerTimestamp[eventWithDate.date]) {
                        usedTypesPerTimestamp[eventWithDate.date] = new Set();
                      }

                      // If this type is already used for this timestamp or the name has already been assigned a time, skip this iteration
                      if (
                        usedTypesPerTimestamp[eventWithDate.date].has(
                          userAndEvent[userName].type
                        ) ||
                        usedNames.has(userAndEvent[userName].userName)
                      ) {
                        return undefined;
                      }

                      // Otherwise, add this type to the set
                      usedTypesPerTimestamp[eventWithDate.date].add(
                        userAndEvent[userName].type
                      );

                      // And add this name to the set
                      usedNames.add(userAndEvent[userName].userName);

                      return {
                        id: `${uindex}${iindex}${eindex}`,
                        date: eventWithDate.date,
                        userName: this.makeWords(
                          userAndEvent[userName].userName
                        ),
                        collapsed: false,
                        integrationName: this.makeWords(integrationName),
                        event: this.makeWords(userAndEvent[userName].event),
                        cursor: this.isExpanded ? "pointer" : "default",
                        expandFunction: this.contractToggle,
                        customClasses: "",
                        badgeId,
                        searchableName,
                        rawUserLabel: userAndEvent[userName].rawLabel,
                        timestampLinkable: eventWithDate.rawDate,
                      };
                    }
                  }
                );
              })
              .flat();
          });

        return accessControlObjects;
      })
      .flat()
      .filter((val): val is AccessControlInfo => !!val);

    // Go through labels and find any custom non-user events
    const additionalAccessControlObjects = this.findNonUserEvents();
    additionalAccessControlObjects.forEach((aco) => {
      accessControlToDisplay.push(aco);
    });

    // Sort the access control objects by time (in place)
    accessControlToDisplay = this.sortAccessControlByTimestamp(
      accessControlToDisplay
    ) as AccessControlInfo[];

    // If there were no compliance labels found, then let the parent know not to show the button
    if (accessControlToDisplay.length === 0) {
      this.$emit("hide-access-control-button");
    }

    // If there is more than 2, for ui reasons, we want to truncate the array
    return this.truncateAccessControlArray(
      accessControlToDisplay
    ) as AccessControlInfo[];
  }

  /** Show a special ui if we have the long label syntax. */
  get fullInfoLabelExists() {
    const allMatchingLabels = this.findLabelWithRegex(
      this.labelsAsSet,
      this.fullInfoIntegrationRegex
    );

    // Check for long label
    return allMatchingLabels.length > 0;
  }

  /** Get legacy access control objects */
  accessControlObjects() {
    if (this.fullInfoLabelExists) {
      return [];
    } else {
      return this.accessControlObjectsLegacy(this.labelsAsSet);
    }
  }

  /** Get new full info access control objects */
  get fullInfoAccessControlObjects() {
    if (this.fullInfoLabelExists) {
      const allMatchingLabels = this.findLabelWithRegex(
        this.labelsAsSet,
        this.fullInfoIntegrationRegex
      );
      return this.accessControlObjectsWithLongLabel(allMatchingLabels);
    } else {
      return [];
    }
  }

  /** Gets the unauthorized entries via subtraction and labels */
  get unauthorizedEntries(): string | null {
    // Note: There should never be 2 so always select first one
    const transitionRegex = this.findLabelWithRegex(
      this.labelsAsSet,
      this.transitionCountRegex
    )[0];
    const unlockRegex = this.findLabelWithRegex(
      this.labelsAsSet,
      this.humanUnlockRegex
    )[0];

    // Don't show anything if we don't have both
    if (!transitionRegex || !unlockRegex) {
      return null;
    }

    const [countTransitions] = transitionRegex.slice(1);
    const [countUnlocks] = unlockRegex.slice(1);

    // make sure both are numbers
    if (isNaN(Number(countTransitions)) || isNaN(Number(countUnlocks))) {
      return null;
    }

    const unauthorizedEntries = Number(countTransitions) - Number(countUnlocks);

    // If we have a negative number, then we have a problem
    if (unauthorizedEntries < 0) {
      return null;
    }

    // if we have 0, then we don't need to show the warning
    if (unauthorizedEntries === 0) {
      return null;
    }

    return `${unauthorizedEntries} unauthorized ${
      unauthorizedEntries == 1 ? "entry" : "entries"
    }`;
  }

  /** Copies the passed in text to the clipboard */
  copyToClipboard(text: string, index: number) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.successfulCopy = true;
          this.successfulCopyIndex = index;
          setTimeout(() => {
            this.successfulCopy = false;
            this.successfulCopyIndex = -1;
          }, 2000);
        })
        .catch((err) => {
          console.error("Error copying text to clipboard: ", err);
        });
    } else {
      console.log("clipboard copy not supported by browser");
    }
  }
}
