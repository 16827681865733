
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TheVideoPlayer from "@/components/TheVideoPlayer.vue";
import ThePanZoomOverlay from "@/components/ThePanZoomOverlay.vue";
import TheDownloadProgressBar from "@/components/TheDownloadProgressBar.vue";
import {
  Bucket,
  Camera,
  CameraNameClickFactoryFunction,
  DownloadProgress,
  VideoInfo,
} from "@/types";
import { VideoJsPlayer } from "video.js";
import dayjs from "dayjs";
import { initDropdown, initPopover } from "@/plugins/bootstrapSetup";

@Component({
  components: {
    TheVideoPlayer,
    ThePanZoomOverlay,
    TheDownloadProgressBar,
  },
})
export default class TheVideoInfo extends Vue {
  // ---------- Props ----------
  /** The video info defined in the camio_viewer.js file. */
  @Prop() videoInfo!: VideoInfo;

  /** The currently active player (since the players swap, this changes as videos come in) */
  @Prop() activePlayer!: VideoJsPlayer;

  /** Both possible videojs players... this is empty initially, then has length two when the players are initialized. */
  @Prop() players!: VideoJsPlayer[] | [];

  /** The current progress of all of the video downloads */
  @Prop() downloadProgress!: DownloadProgress;

  /** Tells us if the videos are still loading. */
  @Prop() videosLoaded!: boolean;

  /** The camera and all it's metadata corresponding to this event. */
  @Prop() eventCamera!: Camera | null;

  /** The current bucket the viewer is using. */
  @Prop() currentBucket: Bucket | undefined;

  /** An outside function for performing a search with a selected label. */
  @Prop() cameraNameClickFactory!: CameraNameClickFactoryFunction;

  // ------- Local Vars --------

  /** The date that changes as the video progresses */
  liveDate: dayjs.Dayjs = dayjs();

  /** The interval that is set up to check the timestamp */
  intervalId!: ReturnType<typeof setInterval>;

  /** The format to put the date in (matches camio_viewer.js currently) */
  dateFormat = "ddd MMMM DD[,] YYYY [-] h:mm:ssa"; // See: https://day.js.org/docs/en/display/format

  /** The maximum allowed length of a string before it's cut in the ui. */
  MAX_ALLOWED_CAMERA_NAME_CHARS = 15;

  // --------- Watchers --------
  @Watch("activePlayer")
  playerUpdate(nextPlayer: VideoJsPlayer, lastPlayer: VideoJsPlayer): void {
    if (nextPlayer) {
      // if there was a previous player, unsubscribe from it's seek events
      if (lastPlayer) {
        lastPlayer.off("seeked");
      }
      // Listen to the next player's seek events
      // TODO: should this be here, or will we want other things to be subscribed to seek?
      nextPlayer.on("seeked", this.updateTimestamp);
    }
  }

  @Watch("players")
  playersReady(): void {
    if (this.players.length > 0) {
      // this should only be called once, but if it is ever called twice,
      // we need to make sure to clean up the interval before starting a new one
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // set up an interval to update the timestamp every second
      this.intervalId = setInterval(() => {
        this.updateTimestamp();
      }, 500);
    }
  }

  // ------- Lifecycle ---------

  beforeDestroy(): void {
    clearInterval(this.intervalId);
  }

  mounted(): void {
    if (this.$refs.videoInfo) {
      initPopover(this.$refs.videoInfo as Element);
      initDropdown(this.$refs.videoInfo as Element);
    }
    this.liveDate = dayjs(this.videoInfo.startDate);
  }
  // --------- Methods ---------

  /** Formats the date into a human-readable format. */
  get formattedTimeStamp(): string {
    return this.liveDate.format(this.dateFormat);
  }

  /** Returns a dash in front of the user alias as long as it exists and isn't an empty string */
  get userAliasString(): string {
    if (
      this.videoInfo &&
      this.videoInfo.userAlias &&
      this.videoInfo.userAlias !== ""
    ) {
      return this.videoInfo.userAlias;
    } else {
      return "";
    }
  }

  /** Trims really long camera names mac style (... in the middle) so we don't get ridiculously long info bars. */
  get trimmedName(): string {
    if (this.videoInfo) {
      const cameraName = this.videoInfo.cameraName;
      const endCharactersVisible = Math.min(
        Math.floor(
          (cameraName.length - this.MAX_ALLOWED_CAMERA_NAME_CHARS) / 2
        ),
        4
      );
      if (cameraName.length > this.MAX_ALLOWED_CAMERA_NAME_CHARS + 3) {
        return (
          cameraName.substring(0, this.MAX_ALLOWED_CAMERA_NAME_CHARS) +
          "..." +
          cameraName.substring(
            cameraName.length - endCharactersVisible,
            cameraName.length
          )
        );
      }
      return cameraName;
    }
    return "";
  }
  /** Keeps track of if the popover should show camera labels */
  get shouldShowCameraLabels(): boolean {
    return !!(
      this.eventCamera &&
      this.eventCamera.labels &&
      this.eventCamera.labels.length > 0
    );
  }

  /** Handles updating the timestamp. */
  updateTimestamp(): void {
    if (this.activePlayer) {
      const currentTime = this.activePlayer.currentTime();
      // simply add the current time to the start date
      this.liveDate = this.videoInfo.startDate.add(currentTime, "seconds");
    }
  }

  /** Handles the dropdown menu click with the cameraNameClickFactory from the parent */
  dropdownMenuClick(label: string, event: Event, isCamera: boolean): void {
    let userId;
    if (this.eventCamera) {
      userId = this.eventCamera.user_id;
    } else if (this.currentBucket) {
      userId = this.currentBucket.user_id;
    }
    if (this.cameraNameClickFactory) {
      this.cameraNameClickFactory(label, true, isCamera, userId)(event);
    } else {
      // On share page, we need to make a new link
      window.location.href = window.location.origin + this.videoInfo.linkHref;
    }
  }
}
