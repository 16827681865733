var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showAccessLogger)?_c('div',{staticClass:"access-logger-container-full",class:{
    'fade-in': _vm.localHoverForTimeout,
    'fade-out': !_vm.localHoverForTimeout,
  }},[(_vm.unauthorizedEntries)?_c('span',{staticClass:"unauthorized-entries"},[_c('b-icon',{attrs:{"icon":"exclamation-lg","color":"red"}}),_vm._v(" "+_vm._s(_vm.unauthorizedEntries)+" ")],1):_vm._e(),_c('div',{ref:"accessLogger",staticClass:"access-logger-container"},[(!_vm.fullInfoLabelExists)?_vm._l((_vm.legacyAccessControlObjects),function(accessObject){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!accessObject.collapsed),expression:"!accessObject.collapsed"}],key:`access-control-${accessObject.id}`,staticClass:"access-control-item",class:accessObject.customClasses,style:({ cursor: accessObject.cursor }),on:{"click":function($event){$event.stopPropagation();return accessObject.expandFunction()}}},[_c('div',{staticClass:"user"},[(_vm.getAccessControlSearchType(accessObject))?_c('div',{staticClass:"searchable",on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{staticClass:"should-link",attrs:{"href":_vm.makeLabelLink(
                  _vm.getAccessControlSearchType(accessObject),
                  false,
                  accessObject.rawUserLabel
                ),"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":"Include this access control event in the search."}},[_vm._v(" "+_vm._s(accessObject.userName)+" ")]),_c('a',{staticClass:"person-badge",attrs:{"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":"Start a new search with just this badge id.","href":_vm.makeLabelLink(
                  _vm.getAccessControlSearchType(accessObject),
                  true,
                  accessObject.rawUserLabel
                )}},[(accessObject.badgeId)?_c('b-icon',{attrs:{"icon":"person-badge","scale":".85"}}):_c('b-icon',{attrs:{"icon":"person","scale":".85"}})],1)]):_c('div',{staticClass:"no-link"},[_vm._v(" "+_vm._s(accessObject.userName)+" ")])]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(accessObject.event)+" at "),_c('span',{class:{
              link: _vm.videosLoaded && accessObject.timestampLinkable,
            },on:{"click":function($event){$event.stopPropagation();return _vm.goToTimestamp(accessObject.timestampLinkable)}}},[_vm._v(_vm._s(accessObject.date))])])])}):_vm._l((_vm.fullInfoAccessControlObjects),function(accessObject,accessControlIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!accessObject.collapsed),expression:"!accessObject.collapsed"}],key:`access-control-${accessObject.id}`,staticClass:"access-control-item",class:accessObject.customClasses,style:({ cursor: accessObject.cursor }),on:{"click":function($event){$event.stopPropagation();return accessObject.expandFunction()}}},[_c('div',{staticClass:"full-info-label-list"},[_vm._l((accessObject.iterable),function(field,index){return [(field.humanReadable && field.searchable)?_c('div',{key:`full-info-label-${index}`,staticClass:"access-control-row"},[_c('a',{class:`${
                  field.readableText ? 'should-link' : 'no-underline'
                } readable-title`,attrs:{"href":field.readableLink,"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":field.readableText
                    ? `Search "${field.readableText}".`
                    : field.humanReadable},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(field.humanReadable)+" ")]),(field.appendedText)?_c('a',{staticClass:"icon-link",attrs:{"href":field.appendedLink,"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":field.appendedText
                    ? `Search "${field.appendedText}" and existing query.`
                    : false},on:{"click":function($event){$event.stopPropagation();}}},[_c('b-icon',{attrs:{"icon":"plus","font-scale":"1.5"}})],1):_c('a',{staticClass:"icon-link",attrs:{"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":field.humanReadable
                    ? `Copy "${field.humanReadable}" to clipboard.`
                    : false},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(field.humanReadable, accessControlIndex)}}},[(
                    _vm.successfulCopy &&
                    accessControlIndex === _vm.successfulCopyIndex
                  )?_c('b-icon',{attrs:{"icon":"check","font-scale":"1.2"}}):_c('b-icon',{attrs:{"icon":"clipboard","font-scale":"1.2"}})],1)]):_c('div',{key:`full-info-label-${index}`,staticClass:"readable-title"},[_vm._v(" "+_vm._s(field.humanReadable)+" ")])]}),(!accessObject.iterable)?[_c('div',{staticClass:"overflow-title"},[_vm._v(" "+_vm._s(accessObject.overflowEventTitle)+" ")])]:_vm._e(),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(accessObject.eventHumanReadable)+" at "),_c('span',{class:{
                link: _vm.videosLoaded && accessObject.timestampLinkable,
              },attrs:{"data-bs-toggle":"popover","data-bs-trigger":"hover","data-bs-container":"#access-logger-container","data-bs-placement":"top","title":"View this time in the video."},on:{"click":function($event){$event.stopPropagation();return _vm.goToTimestamp(accessObject.timestampLinkable)}}},[_vm._v(_vm._s(accessObject.date))])])],2)])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }