
import { initPopover } from "@/plugins/bootstrapSetup";
import { Popover } from "bootstrap";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios, { AxiosRequestConfig } from "axios";
import { Bucket } from "@/types";

enum EmailState {
  NOT_SENT,
  SENDING,
  SENT,
  FAILED,
}

@Component({})
export default class TheSendTailgatingEmailButton extends Vue {
  // ---------- Props ----------

  /** The list of labels for this event as a set (no duplicates) */
  @Prop() labelsAsSet!: string[];

  /** The bucket for this event. */
  @Prop() currentBucket!: Bucket;

  // ------- Local Vars --------

  /** Reference to all popovers so we can modify them (Note that in this component, there is only one). */
  popovers!: Popover[];

  /** Whether or not we are currently queueing emails. */
  state: EmailState = EmailState.NOT_SENT;

  /** The url for sending manual tailgating emails */
  TAILGATING_URL_POST = "/api/notifications/tailgating";

  // --------- Watchers --------

  @Watch("state")
  queueChange(): void {
    this.setDynamicHelpMessage();
  }

  /** Helper for setting dynamic help messages */
  setDynamicHelpMessage(): void {
    // Need to essentially delete and rebuild because otherwise popover content doesn't change
    if (this.popovers) {
      // NOTE: there is only one, but this is for robustness
      this.popovers.forEach((popover) => {
        popover.dispose();
      });
      this.popovers = initPopover(
        this.$refs.sendTailgatingEmailButton as Element,
        this.dynamicHelpMessage
      );
    }
  }
  // ------- Lifecycle ---------

  mounted(): void {
    if (this.$refs.sendTailgatingEmailButton) {
      this.popovers = initPopover(
        this.$refs.sendTailgatingEmailButton as Element,
        this.dynamicHelpMessage
      );
    }
  }
  // --------- Methods ---------

  /** Choose an icon based on the state. */
  get chooseIcon(): string[] {
    switch (this.state) {
      case EmailState.SENDING:
        return [];
      case EmailState.SENT:
        return ["check"];
      case EmailState.FAILED:
        return ["arrow-clockwise"];
      default:
        return ["envelope"];
    }
  }

  /** Gets the dynamic help message. */
  get dynamicHelpMessage(): string {
    switch (this.state) {
      case EmailState.SENDING:
        return "Sending emails...";
      case EmailState.SENT:
        return "Emails sent!";
      case EmailState.FAILED:
        return "Failed to send emails. Try again?";
      default:
        return "Send email to each person involved.";
    }
  }

  /** Determines whether or not it is okay to send tailgating emails. */
  get canSendTailgatingEmails(): boolean {
    return this.currentBucket && this.labelsAsSet.includes("tailgating");
  }

  /** Determines whether or not we are currently sending emails. */
  get sending(): boolean {
    return this.state === EmailState.SENDING;
  }

  /** Determines whether or not we have successfully sent emails. */
  get sent(): boolean {
    return this.state === EmailState.SENT;
  }

  /** Sends emails to each person that allowed tailgating */
  async sendEmails(): Promise<void> {
    this.state = EmailState.SENDING;
    const settings: AxiosRequestConfig = {
      url: this.TAILGATING_URL_POST,
      params: {
        event_id: this.currentBucket.bucket_id,
      },
      method: "POST",
    };
    try {
      await axios(settings);
    } catch (err) {
      console.error(err);
      this.state = EmailState.FAILED;
      return;
    }
    this.state = EmailState.SENT;
  }
}
