import Vue from "vue";
/********************************************
 ***************** Plugins ******************
 ********************************************/

import Vue2TouchEvents from "vue2-touch-events";
import { IconsPlugin } from "bootstrap-vue";
import "@/plugins/video-player-download";

Vue.use(IconsPlugin);
Vue.use(Vue2TouchEvents);

/*******************************************
 ************** Watch For Init **************
 ********************************************/

import CamioViewer from "./CamioViewer.vue";
import { initFFMPEG } from "./fetch";
// Initialize ffmpeg ASAP
initFFMPEG(5);

// Select the node that will be observed for mutations
let targetNode = document.querySelector("#video-players");

// Just in case we accidentally mess something up
if (targetNode == null) {
  targetNode = document.querySelector(".container.main-body");
}
// Options for the observer (which mutations to observe)
const config = { attributes: true, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = (mutations: MutationRecord[], observer: MutationObserver) => {
  for (const mutation of mutations) {
    if (mutation.type === "childList") {
      for (const newNode of mutation.addedNodes) {
        if ((newNode as HTMLElement).id === "camio-viewer-vue") {
          embedVueInstance();
          break;
        }
      }
    }
  }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode as Node, config);

export const embedVueInstance = (): void => {
  (window as any).embeddedVueViewerInstance = new Vue({
    render: (h) => h(CamioViewer),
  }).$mount("#camio-viewer-vue");
};

// This is for when debug is active and making sure it still looks okay.
if ((window as any).DEBUG_NEW_VIEWER) {
  console.log("adding on element to trick viewer");
  const camioViewer = document.createElement("div");
  camioViewer.id = "camio-viewer-vue";
  document.querySelector("#video-players")?.append(camioViewer);
  document
    .getElementById("panel-fatrow")
    ?.setAttribute("style", "display: block;");
  document.querySelector(".camio-loader")?.setAttribute("style", "z-index: 0;");
}
